import type { FlowbiteTooltipTheme } from 'flowbite-react';

const theme: FlowbiteTooltipTheme = {
	target: 'tw-w-fit',
	animation: 'tw-transition-opacity',
	arrow: {
		base: 'tw-absolute tw-z-10 tw-h-2 tw-w-2 tw-rotate-45',
		style: {
			dark: 'tw-bg-gray-900 dark:tw-bg-gray-700',
			light: 'tw-bg-white',
			auto: 'tw-bg-white dark:tw-bg-gray-700',
		},
		placement: '-4px',
	},
	base: 'tw-absolute tw-inline-block tw-z-10 tw-rounded-lg tw-py-2 tw-px-3 tw-text-sm tw-font-medium tw-shadow-sm',
	hidden: 'tw-invisible tw-opacity-0',
	style: {
		dark: 'tw-bg-gray-900 tw-text-white dark:tw-bg-gray-700',
		light: 'tw-border tw-border-gray-200 tw-bg-white tw-text-gray-900',
		auto: 'tw-border tw-border-gray-200 tw-bg-white tw-text-gray-900 dark:tw-border-none dark:tw-bg-gray-700 dark:tw-text-white',
	},
	content: 'tw-relative tw-z-20 tw-flex',
};

export default theme;
