import type { FlowbiteTextareaTheme } from 'flowbite-react';

const theme: FlowbiteTextareaTheme = {
	base: 'tw-block tw-w-full tw-rounded-lg tw-border disabled:tw-cursor-not-allowed disabled:tw-opacity-50 tw-caret-primary tw-resize-none',
	colors: {
		gray: 'tw-bg-gray-50 tw-border-gray-200 tw-text-gray-600 focus:tw-border-primary-500 focus:tw-ring-primary-500 dark:tw-border-gray-700 dark:tw-bg-gray-800 dark:tw-text-white dark:tw-placeholder-gray-400 dark:focus:tw-border-primary-500 dark:focus:tw-ring-primary-500',
		info: 'tw-border-blue-500 tw-bg-blue-50 tw-text-blue-900 tw-placeholder-blue-700 focus:tw-border-blue-500 focus:tw-ring-blue-500 dark:tw-border-blue-400 dark:tw-bg-blue-100 dark:focus:tw-border-blue-500 dark:focus:tw-ring-blue-500',
		failure:
			'tw-border-red-500 tw-bg-red-50 tw-text-red-900 tw-placeholder-red-700 focus:tw-border-red-500 focus:tw-ring-red-500 dark:tw-border-red-400 dark:tw-bg-red-100 dark:focus:tw-border-red-500 dark:focus:tw-ring-red-500',
		warning:
			'tw-border-yellow-500 tw-bg-yellow-50 tw-text-yellow-900 tw-placeholder-yellow-700 focus:tw-border-yellow-500 focus:tw-ring-yellow-500 dark:tw-border-yellow-400 dark:tw-bg-yellow-100 dark:focus:tw-border-yellow-500 dark:focus:tw-ring-yellow-500',
		success:
			'tw-border-green-500 tw-bg-green-50 tw-text-green-900 tw-placeholder-green-700 focus:tw-border-green-500 focus:tw-ring-green-500 dark:tw-border-green-400 dark:tw-bg-green-100 dark:focus:tw-border-green-500 dark:focus:tw-ring-green-500',
		primary:
			'tw-border-primary-500 tw-bg-primary-50 tw-text-primary-900 tw-placeholder-primary-700 focus:tw-border-primary-500 focus:tw-ring-primary-500 dark:tw-border-primary-400 dark:tw-bg-primary-100 dark:focus:tw-border-primary-500 dark:focus:tw-ring-primary-500',
	},
	withShadow: {
		on: 'tw-shadow-sm dark:tw-shadow-sm-light',
		off: '',
	},
};

export default theme;
