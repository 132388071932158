import type { FlowbiteDropdownTheme } from 'flowbite-react';

const theme: FlowbiteDropdownTheme = {
	arrowIcon: 'tw-ms-2 tw-h-4 tw-w-4',
	content: 'tw-py-1 focus:tw-outline-none tw-px-0 tw-m-0',
	floating: {
		animation: 'tw-transition-opacity',
		arrow: {
			base: 'tw-absolute tw-z-10 tw-h-2 tw-w-2 tw-rotate-45',
			style: {
				dark: 'tw-bg-gray-900 dark:tw-bg-gray-700',
				light: 'tw-bg-white',
				auto: 'tw-bg-white dark:tw-bg-gray-700',
			},
			placement: '-4px',
		},
		base: 'tw-z-10 tw-w-fit tw-rounded tw-divide-y tw-divide-gray-100 tw-shadow focus:tw-outline-none',
		content: 'tw-py-1 tw-text-sm tw-text-gray-700 dark:tw-text-gray-200',
		divider: 'tw-my-1 tw-h-px tw-bg-gray-100 dark:tw-bg-gray-600',
		header: 'tw-block tw-py-2 tw-px-4 tw-text-sm tw-text-gray-700 dark:tw-text-gray-200',
		hidden: 'tw-invisible tw-opacity-0',
		item: {
			container: '',
			base: 'tw-flex tw-items-center tw-justify-start tw-py-2 tw-px-4 tw-text-md tw-text-gray-700 tw-cursor-pointer tw-w-full hover:tw-bg-gray-100 focus:tw-bg-gray-100 dark:tw-text-gray-200 dark:hover:tw-bg-gray-600 focus:tw-outline-none dark:hover:tw-text-white dark:focus:tw-bg-gray-600 dark:focus:tw-text-white',
			icon: 'tw-me-2 tw-h-4 tw-w-4',
		},
		style: {
			dark: 'tw-bg-gray-900 tw-text-white dark:tw-bg-gray-700',
			light: 'tw-border tw-border-gray-200 tw-bg-white tw-text-gray-900',
			auto: 'tw-border tw-border-gray-200 tw-bg-white tw-text-gray-900 dark:tw-border-none dark:tw-bg-gray-800 dark:tw-text-white',
		},
		target: 'tw-w-fit',
	},
	inlineWrapper: 'tw-flex tw-items-center',
};

export default theme;
