import type { FlowbiteLabelTheme } from 'flowbite-react';

const theme: FlowbiteLabelTheme = {
	root: {
		base: 'tw-text-sm tw-font-medium [&>span]:tw-capitalize',
		disabled: 'tw-opacity-50',
		colors: {
			default: 'tw-text-gray-600 dark:tw-text-white',
			info: 'tw-text-cyan-500 dark:tw-text-cyan-600',
			failure: 'tw-text-red-500 dark:tw-text-red-500',
			warning: 'tw-text-yellow-500 dark:tw-text-yellow-600',
			success: 'tw-text-green-700 dark:tw-text-green-500',
		},
	},
};

export default theme;
