import React, { useState, createContext, useContext, forwardRef, isValidElement } from 'react';
import {
	autoUpdate,
	flip,
	FloatingPortal,
	limitShift,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useHover,
	useId,
	useInteractions,
	useMergeRefs,
	useRole,
	useTransitionStyles,
	type UseFloatingReturn,
} from '@floating-ui/react';

import { remToPx, safeString } from '@utils';
import classNames from 'classnames';

export type IUsePopover = {
	initialOpen?: boolean;
	placement?:
		| 'bottom'
		| 'left'
		| 'right'
		| 'top'
		| 'bottom-start'
		| 'bottom-end'
		| 'top-start'
		| 'top-end'
		| 'left-start'
		| 'left-end'
		| 'right-start'
		| 'right-end';
	modal?: boolean;
	open?: boolean;
	onOpenChange?: React.Dispatch<React.SetStateAction<boolean>>;
	useHoverProp?: boolean;
};

export type IInteractions = {
	getReferenceProps: (userProps?: React.HTMLProps<Element>) => Record<string, unknown>;
	getFloatingProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
	getItemProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
};

export type ITransition = {
	isMounted: boolean;
	styles: React.CSSProperties;
};

export type IUsePopoverReturn = IUsePopover & IInteractions & ITransition & UseFloatingReturn<any>;

export const usePopover = ({
	initialOpen = false,
	placement = 'bottom',
	modal,
	open: controlledOpen,
	onOpenChange: setControlledOpen,
}: IUsePopover): IUsePopoverReturn => {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
	const [labelId, setLabelId] = useState('');
	const [descriptionId, setDescriptionId] = useState('');

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const data: UseFloatingReturn<any> = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(remToPx(10 / 16)),
			flip({
				fallbackAxisSideDirection: 'end',
				crossAxis: false,
			}),
			shift({
				limiter: limitShift({ offset: remToPx(15 / 16) }),
			}),
		],
	});

	const context = data.context;

	const click = useClick(context, {
		enabled: controlledOpen == null,
	});
	const dismiss = useDismiss(context);
	const role = useRole(context);
	const hover = useHover(context);

	const interactions: IInteractions = useInteractions([click, dismiss, role, hover]);

	const transition: ITransition = useTransitionStyles(context, {
		duration: {
			open: 400,
			close: 75,
		},
		initial: ({ side }) => ({
			opacity: 0,
			transform: {
				top: 'translateY(-0.5rem)',
				right: 'translateX(0.5rem)',
				bottom: 'translateY(0.5rem)',
				left: 'translateX(-0.5rem)',
			}[side],
		}),
		close: () => ({
			opacity: 0,
			transform: 'scale(0.97)',
		}),
	});

	return React.useMemo(
		() => ({
			open,
			setOpen,
			modal,
			labelId,
			descriptionId,
			setLabelId,
			setDescriptionId,
			...interactions,
			...data,
			...transition,
		}),
		[open, setOpen, interactions, data, modal, labelId, descriptionId, transition],
	);
};

const PopoverContext = createContext<IUsePopoverReturn | null>(null);

export const usePopoverContext = (): any => {
	const context = useContext(PopoverContext);

	if (context == null) {
		throw new Error('Popover components must be wrapped in <Popover />');
	}

	return context;
};

export const PopoverComponent = ({ children, modal = false, ...restOptions }: React.PropsWithChildren<IUsePopover>) => {
	// This can accept any props as options, e.g. `placement`,
	// or other positioning options.
	const popover = usePopover({ modal, ...restOptions });
	return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
};

interface PopoverTriggerProps {
	children: React.ReactNode;
	asChild?: boolean;
}

const PopoverTrigger = forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & PopoverTriggerProps>(
	({ children, asChild = false, ...props }, propRef) => {
		const context = usePopoverContext();
		const childrenRef = (children as any).ref;
		let childProps = (children as any)?.props;
		if (childProps) {
			const { asChild: _asChild, ...rest } = (children as any).props;
			childProps = rest;
		}
		const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

		// `asChild` allows the user to pass any element as the anchor
		if (asChild && isValidElement(children)) {
			return React.cloneElement(
				children,
				context.getReferenceProps({
					ref,
					...props,
					...childProps,
					'data-state': context.open ? 'open' : 'closed',
				}),
			);
		}

		return (
			<button
				ref={ref}
				type="button"
				// The user can style the trigger based on the state
				data-state={context.open ? 'open' : 'closed'}
				{...context.getReferenceProps(props)}
			>
				{children}
			</button>
		);
	},
);

const PopoverContent = forwardRef((props: any, propRef: any) => {
	const { context: floatingContext, ...context } = usePopoverContext();
	const ref = useMergeRefs([context.refs.setFloating, propRef]);
	const { className, asChild = false, children } = props;

	if (context.isMounted && asChild) {
		return (
			<div
				ref={ref}
				style={{
					position: context.strategy,
					top: context.y ?? 0,
					left: context.x ?? 0,
					width: 'max-content',
					...props.style,
					...context.styles,
				}}
				aria-labelledby={context.labelId}
				aria-describedby={context.descriptionId}
				{...context.getFloatingProps(props)}
				className={classNames(
					'tw-z-50 tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-2 tw-text-gray-600 tw-shadow-sm tw-outline-none dark:tw-border-gray-700 dark:tw-bg-gray-800 dark:tw-text-white',
					{
						[safeString(className)]: !!className,
					},
				)}
			>
				{props.children}
			</div>
		);
	}

	return (
		<FloatingPortal>
			{context.isMounted && (
				<div
					ref={ref}
					style={{
						position: context.strategy,
						top: context.y ?? 0,
						left: context.x ?? 0,
						width: 'max-content',
						...props.style,
						...context.styles,
					}}
					aria-labelledby={context.labelId}
					aria-describedby={context.descriptionId}
					{...context.getFloatingProps(props)}
					className={classNames(
						'tw-z-50 tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-2 tw-text-gray-600 tw-shadow-sm tw-outline-none dark:tw-border-gray-700 dark:tw-bg-gray-800 dark:tw-text-white',
						{
							[safeString(className)]: !!className,
						},
					)}
				>
					{props.children}
				</div>
			)}
		</FloatingPortal>
	);
});

export const PopoverHeading = forwardRef(({ children, ...props }: any, ref) => {
	const { setLabelId } = usePopoverContext();
	const id = useId();

	// Only sets `aria-labelledby` on the Popover root element
	// if this component is mounted inside it.
	React.useLayoutEffect(() => {
		setLabelId(id);
		return () => setLabelId(undefined);
	}, [id, setLabelId]);

	return (
		<h2 {...props} ref={ref} id={id}>
			{children}
		</h2>
	);
});

export const PopoverDescription = forwardRef(({ children, ...props }: any, ref) => {
	const { setDescriptionId } = usePopoverContext();
	const id = useId();

	// Only sets `aria-describedby` on the Popover root element
	// if this component is mounted inside it.
	React.useLayoutEffect(() => {
		setDescriptionId(id);
		return () => setDescriptionId(undefined);
	}, [id, setDescriptionId]);

	return (
		<p {...props} ref={ref} id={id}>
			{children}
		</p>
	);
});

export const PopoverClose = forwardRef(({ children, ...props }: any, ref) => {
	const { setOpen } = usePopoverContext();
	return (
		<button
			type="button"
			{...props}
			ref={ref}
			onClick={(event) => {
				props.onClick?.(event);
				setOpen(false);
			}}
		>
			{children}
		</button>
	);
});

export const Popover = Object.assign(PopoverComponent, {
	Trigger: PopoverTrigger,
	Content: PopoverContent,
	Heading: PopoverHeading,
	Description: PopoverDescription,
});
