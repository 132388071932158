import type { CustomFlowbiteTheme } from 'flowbite-react';

import accordionTheme from './components/Accordion';
import alertTheme from './components/Alert';
import badgeTheme from './components/Badge';
import buttonTheme from './components/Button';
import buttonGroupTheme from './components/ButtonGroup';
import breadcrumbTheme from './components/Breadcrumb';
import cardTheme from './components/Card';
import checkboxTheme from './components/Checkbox';
import dropdownTheme from './components/Dropdown';
import helperTextTheme from './components/HelperText';
import modalTheme from './components/Modal';
import labelTheme from './components/Label';
import listGroupTheme from './components/ListGroup';
import paginationTheme from './components/Pagination';
import tableTheme from './components/Table';
import tabTheme from './components/Tab';
import textareaTheme from './components/Textarea';
import textInputTheme from './components/TextInput';
import timelineTheme from './components/Timeline';
import toggleSwitchTheme from './components/ToggleSwitch';
import tooltipTheme from './components/Tooltip';
import sidebarTheme from './components/Sidebar';
import spinnerTheme from './components/Spinner';

const theme: CustomFlowbiteTheme = {
	accordion: accordionTheme,
	alert: alertTheme,
	badge: badgeTheme,
	breadcrumb: breadcrumbTheme,
	button: buttonTheme,
	buttonGroup: buttonGroupTheme,
	card: cardTheme,
	checkbox: checkboxTheme,
	dropdown: dropdownTheme,
	helperText: helperTextTheme,
	modal: modalTheme,
	label: labelTheme,
	listGroup: listGroupTheme,
	pagination: paginationTheme,
	tab: tabTheme,
	table: tableTheme,
	textarea: textareaTheme,
	textInput: textInputTheme,
	timeline: timelineTheme,
	toggleSwitch: toggleSwitchTheme,
	tooltip: tooltipTheme,
	sidebar: sidebarTheme,
	spinner: spinnerTheme,
};

export default theme;
