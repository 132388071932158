import type { FlowbiteModalTheme } from 'flowbite-react';

const theme: FlowbiteModalTheme = {
	root: {
		base: 'tw-fixed tw-top-0 tw-bottom-0 tw-right-0 tw-left-0 tw-z-50 tw-h-modal tw-h-screen tw-overflow-y-auto tw-overflow-x-hidden md:tw-inset-0 md:tw-h-full',
		show: {
			on: 'tw-flex tw-bg-gray-900 tw-bg-opacity-50 dark:tw-bg-opacity-80',
			off: 'tw-hidden',
		},
		sizes: {
			sm: 'tw-max-w-sm',
			md: 'tw-max-w-md',
			lg: 'tw-max-w-lg',
			xl: 'max-w-xl',
			'2xl': 'tw-max-w-2xl',
			'3xl': 'tw-max-w-3xl',
			'4xl': 'tw-max-w-4xl',
			'5xl': 'tw-max-w-5xl',
			'6xl': 'tw-max-w-6xl',
			'7xl': 'tw-max-w-7xl',
		},
		positions: {
			'top-left': 'tw-items-start tw-justify-start',
			'top-center': 'tw-items-start tw-justify-center',
			'top-right': 'tw-items-start tw-justify-end',
			'center-left': 'tw-items-center tw-justify-start',
			center: 'tw-items-center tw-justify-center',
			'center-right': 'tw-items-center tw-justify-end',
			'bottom-right': 'tw-items-end tw-justify-end',
			'bottom-center': 'tw-items-end tw-justify-center',
			'bottom-left': 'tw-items-end tw-justify-start',
		},
	},
	content: {
		base: 'tw-relative tw-h-fit tw-w-full tw-p-4 md:tw-h-auto',
		inner: 'tw-relative tw-rounded-lg tw-bg-white tw-shadow dark:tw-bg-gray-800 tw-flex tw-flex-col tw-max-h-[90vh]',
	},
	body: {
		base: 'tw-p-6 tw-flex-1 tw-overflow-auto',
		popup: 'tw-pt-0',
	},
	header: {
		base: 'tw-flex tw-items-center tw-justify-between tw-rounded-t dark:tw-border-gray-700 tw-border-b tw-p-5',
		popup: 'tw-p-2 tw-border-b-0',
		title: 'tw-text-xl tw-font-medium tw-text-gray-600 dark:tw-text-white tw-mb-0',
		close: {
			base: 'tw-ms-auto tw-inline-flex tw-items-center tw-rounded-lg tw-bg-transparent tw-p-1.5 tw-text-sm tw-text-gray-400 hover:tw-bg-gray-200 hover:tw-text-gray-900 dark:hover:tw-bg-gray-600 dark:hover:tw-text-white',
			icon: 'tw-h-5 tw-w-5',
		},
	},
	footer: {
		base: 'tw-flex tw-items-center tw-space-x-2 tw-rounded-b tw-border-gray-200 tw-p-6 dark:tw-border-gray-700',
		popup: 'tw-border-t',
	},
};

export default theme;
