import React from 'react';
import classNames from 'classnames';

import { Spinner } from 'flowbite-react';

import useLoading from '@hooks/useLoading';
import { safeString } from '@utils';

import './ui-loader.scss';

export type IUILoader = {
	isTopLevel?: boolean;
	blocked?: boolean;
	className?: string;
	overlayClassName?: string;
};

/**
 * A React component that renders an UI loader with optional blocking and global styles.
 *
 * @param {React.PropsWithChildren<IUILoader>} props - The props object that contains children, global, and blocked.
 * @param {React.ReactNode} props.children - The child elements to render inside the UI loader.
 * @param {boolean} [props.global] - Whether to apply application wide loader or not.
 * @param {boolean} [props.blocked] - Whether the UI should be blocked or not.
 * @return {React.ReactElement} A React element that renders the UI loader with optional blocking and global styles.
 */
export const UILoader: React.FC<React.PropsWithChildren<IUILoader>> = ({
	children,
	isTopLevel = true,
	blocked = false,
	className,
	overlayClassName,
}: React.PropsWithChildren<IUILoader>): React.ReactElement => {
	const [loading] = useLoading();
	const isBlocked = isTopLevel ? loading : blocked;

	/****
	 * Possible classes to have
	 * tw-relative tw-z-[100] tw-z-0 tw-z-[-1]
	 ****/

	return (
		<div
			className={classNames('ui-loader', {
				show: isBlocked,
				'tw-cursor-progress': isBlocked,
				'tw-relative': !isTopLevel,
				'tw-z-[100]': !isTopLevel && !safeString(className).includes('tw-z-'),
				'tw-z-[-1]': !isBlocked && !safeString(className).includes('tw-z-'),
				[safeString(className)]: !!className,
			})}
		>
			{children}
			{isBlocked ? (
				<>
					<div
						className={classNames('overlay tw-bg-primary/[.1]', {
							[safeString(overlayClassName)]: !!overlayClassName,
						})}
					></div>
					<div className="loader">{<Spinner color="primary" size="lg" />}</div>
				</>
			) : null}
		</div>
	);
};
