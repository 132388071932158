import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Dropdown, Pagination } from 'flowbite-react';

import { IListingPagination } from '@types';
import { safeString } from '@utils';

export type IFooter = {
	className?: string;
	pageSizes?: number[];
	onPageSizeChange: (pageSize: string | number) => void;
	currentPageSize: string | number;
	paginationData?: IListingPagination;
	onPageChange: (page: number) => void;
};

/**
 * Renders a table footer component with pagination and page size options.
 *
 * @param {IFooter} props - The props object containing:
 *     paginationData: The pagination data object.
 *     currentPageSize: The current page size.
 *     className: The CSS class name for the component.
 *     pageSizes: An array of page size options.
 *     hasPreviousPage: Flag indicating if there is a previous page.
 *     hasNextPage: Flag indicating if there is a next page.
 *     goToPreviousPage: Function to go to the previous page.
 *     goToNextPage: Function to go to the next page.
 *     onPageSizeChange: Function to handle page size changes.
 * @return {React.ReactElement} - The rendered table footer component.
 */
export const Footer: React.FC<IFooter> = (props: IFooter): React.ReactElement => {
	const {
		paginationData,
		currentPageSize,
		className,
		pageSizes = [12, 24, 50],
		onPageSizeChange,
		onPageChange,
	} = props;
	const { t } = useTranslation();

	return (
		<div
			className={classNames(
				'tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-lg tw-bg-white tw-p-4 dark:tw-bg-gray-800',
				{
					[safeString(className)]: !!className,
				},
			)}
		>
			<div className="tw-flex tw-items-center">
				<Dropdown size="sm" color="primary" label={currentPageSize}>
					{pageSizes.map((size) => {
						return (
							<Dropdown.Item
								key={size}
								onClick={() => {
									if (safeString(currentPageSize) !== safeString(size)) {
										onPageSizeChange(size);
									}
								}}
							>
								<span>{size}</span>
							</Dropdown.Item>
						);
					})}
				</Dropdown>
				<span className="tw-hidden tw-ps-4 tw-text-gray-600 dark:tw-text-white md:tw-block">
					{t('entriesPerPage')}
				</span>
			</div>
			<span className="tw-hidden tw-text-gray-600 dark:tw-text-white md:tw-block">
				{paginationData
					? t('viewingNumberOfEntriesInPageNth', {
							entryCount: paginationData.resultEntities,
							totalCount: paginationData.totalCount,
							pageNumber: paginationData.page,
					  })
					: ''}
			</span>
			<Pagination
				className="tw-m-0 tw-p-0"
				currentPage={paginationData ? paginationData.page : 1}
				showIcons
				nextLabel={safeString(t('next'))}
				previousLabel={safeString(t('previous'))}
				layout="navigation"
				totalPages={
					paginationData ? (paginationData.hasMore ? paginationData.page + 1 : paginationData.page) : 1
				}
				onPageChange={onPageChange}
			/>
		</div>
	);
};
