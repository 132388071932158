import React from 'react';

export type IIcon = {
	height: string;
	width: string;
	backgroundUrl: string;
};

const PrimaryComponent: React.FC<IIcon> = (props: IIcon): React.ReactElement => {
	const { height, width, backgroundUrl } = props;
	return (
		<div
			style={{
				height: `${height}`,
				width: `${width}`,
				backgroundRepeat: 'no-repeat',
				maskRepeat: 'no-repeat',
				backgroundPosition: 'center',
				backgroundSize: `${height}`,
				backgroundColor: '#fba708',
				WebkitMaskImage: `url(${backgroundUrl})`,
				WebkitMaskRepeat: 'no-repeat',
				maskImage: `url(${backgroundUrl})`,
				WebkitMaskPosition: 'center',
			}}
		></div>
	);
};

export const Icon = Object.assign(
	{},
	{
		Primary: PrimaryComponent,
	},
);
