import * as Sentry from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/style.scss';
import './tailwind.css';

import axios from 'axios';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import 'moment/dist/locale/ar';
import 'moment/dist/locale/tr';

import { Flowbite, Spinner } from 'flowbite-react';
import ToasterDiv from '@views/misc/toaster-div';
import { UILoader, GlobalDrawer } from '@components';
const LazyApp = lazy(() => import('./App'));

import ability from './configs/acl/ability';
import flowbiteTheme from '@src/utility/themes/flowbite';
import { GlobalDrawerContext } from '@contexts/GlobalDrawer';
import { AbilityContext } from '@contexts/Can';




import './configs/i18n';

if (import.meta.env.VITE_APM_ACTIVE) {
	Sentry.init({
		dsn: 'https://60b3f811a2664f46965d9bee2698e29e@sentry.dcafood.com/2',
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
		environment: import.meta.env.VITE_APM_ENVIRONMENT ?? 'local',
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
	SentryBrowser.init({
		dsn: 'https://60b3f811a2664f46965d9bee2698e29e@sentry.dcafood.com/2',
		integrations: [new SentryBrowser.BrowserTracing(), new SentryBrowser.BrowserProfilingIntegration()],
		environment: import.meta.env.VITE_APM_ENVIRONMENT ?? 'local',
		tracesSampleRate: 1.0,
		profilesSampleRate: 1.0,
	});
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

const container = document.getElementById('root')!;
const root = createRoot(container);
axios.defaults.baseURL = '/api';

root.render(
	<QueryClientProvider client={queryClient}>
	
		<BrowserRouter>
			<Flowbite theme={{ theme: flowbiteTheme }}>
			<QueryParamProvider adapter={ReactRouter6Adapter}>

				<Suspense
					fallback={
						<div className="tw-flex tw-h-screen tw-w-screen tw-items-center tw-justify-center tw-bg-white dark:tw-bg-gray-900">
							<Spinner color="primary" size="lg" />
						</div>
					}
				>
					<AbilityContext.Provider value={ability}>
						<GlobalDrawerContext>
							<UILoader />
							<GlobalDrawer />
							<LazyApp />
							<ToasterDiv />
						</GlobalDrawerContext>
					</AbilityContext.Provider>
				</Suspense>
				</QueryParamProvider>

			</Flowbite>
		</BrowserRouter>
		<ReactQueryDevtools initialIsOpen={false} />
	
	</QueryClientProvider>,
);
