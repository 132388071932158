import type { FlowbiteBreadcrumbTheme } from 'flowbite-react';

const theme: FlowbiteBreadcrumbTheme = {
	root: {
		base: '',
		list: 'tw-flex tw-items-center tw-mb-0 tw-ps-0 tw-mt-0',
	},
	item: {
		base: 'tw-group tw-flex tw-items-center',
		chevron: 'tw-mx-1 tw-h-4 tw-w-4 tw-text-gray-400 group-first:tw-hidden md:tw-mx-2',
		href: {
			off: 'tw-flex tw-items-center tw-text-lg tw-font-medium tw-text-gray-500 dark:tw-text-gray-400',
			on: 'tw-flex tw-items-center tw-text-lg tw-font-medium tw-text-gray-700 hover:tw-text-gray-900 dark:tw-text-gray-400 dark:hover:tw-text-white',
		},
		icon: 'tw-mr-2 tw-h-4 tw-w-4',
	},
};
export default theme;
