import React from 'react';
import classNames from 'classnames';

import { safeString } from '@utils';

export type IHeader = {
	className?: string;
};

/**
 * A React functional component that renders the table header with given props.
 *
 * @param {React.PropsWithChildren<IHeader>} props - The props object containing className and children.
 * @return {React.ReactElement} A React element representing the table header.
 */
export const Header: React.FC<React.PropsWithChildren<IHeader>> = (
	props: React.PropsWithChildren<IHeader>,
): React.ReactElement => {
	const { className, children } = props;

	return (
		<thead
			className={classNames('tw-rounded-t-md tw-bg-thead-bg tw-align-bottom dark:tw-bg-thead-dark-bg', {
				[safeString(className)]: !!className,
			})}
		>
			{children}
		</thead>
	);
};
