import type { FlowbiteButtonGroupTheme } from 'flowbite-react';

export const theme: FlowbiteButtonGroupTheme = {
	base: 'tw-inline-flex',
	position: {
		none: 'focus:tw-ring-2',
		start: 'tw-rounded-e-none',
		middle: 'tw-rounded-none tw-border-s-0 tw-ps-0',
		end: 'tw-rounded-s-none tw-border-s-0 tw-ps-0',
	},
};

export default theme;
