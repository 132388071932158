import type { FlowbiteSidebarTheme } from 'flowbite-react';

const theme: FlowbiteSidebarTheme = {
	root: {
		base: 'tw-h-full',
		collapsed: {
			on: 'tw-w-16',
			off: 'tw-w-64',
		},
		inner: 'tw-h-full tw-overflow-y-auto tw-overflow-x-hidden tw-rounded tw-rounded-e-none tw-bg-white tw-pb-4  tw-px-3 dark:tw-bg-gray-800',
	},
	collapse: {
		button: 'tw-group tw-flex tw-w-full tw-items-center tw-rounded-lg tw-p-2 tw-text-lg tw-font-normal tw-text-gray-900 tw-transition tw-duration-75 hover:tw-bg-gray-100 dark:tw-text-white dark:hover:tw-bg-gray-700',
		icon: {
			base: 'tw-h-6 tw-w-6 tw-text-gray-500 tw-transition tw-duration-75 group-hover:tw-text-gray-900 dark:tw-text-gray-400 dark:group-hover:tw-text-white',
			open: {
				off: '',
				on: 'tw-text-gray-900',
			},
		},
		label: {
			base: 'tw-ms-3 tw-flex-1 tw-whitespace-nowrap tw-text-start  tw-text-md',
			icon: 'tw-h-6 tw-w-6',
		},
		list: 'tw-space-y-2 tw-py-2 tw-px-0',
	},
	cta: {
		base: 'tw-mt-6 tw-rounded-lg tw-p-4 tw-bg-gray-100 dark:tw-bg-gray-700',
		color: {
			blue: 'tw-bg-blue-50 dark:tw-bg-blue-900',
			dark: 'tw-bg-dark-50 dark:tw-bg-dark-900',
			failure: 'tw-bg-red-50 dark:tw-bg-red-900',
			gray: 'tw-bg-alternative-50 dark:tw-bg-alternative-900',
			green: 'tw-bg-green-50 dark:tw-bg-green-900',
			light: 'tw-bg-light-50 dark:tw-bg-light-900',
			red: 'tw-bg-red-50 dark:tw-bg-red-900',
			purple: 'tw-bg-purple-50 dark:tw-bg-purple-900',
			success: 'tw-bg-green-50 dark:tw-bg-green-900',
			yellow: 'tw-bg-yellow-50 dark:tw-bg-yellow-900',
			warning: 'tw-bg-yellow-50 dark:tw-bg-yellow-900',
		},
	},
	item: {
		base: 'tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-p-2 tw-font-normal tw-text-gray-900 hover:tw-bg-gray-100 dark:tw-text-white dark:hover:tw-bg-gray-700  tw-text-lg',
		active: 'tw-bg-gray-100 dark:tw-bg-gray-700',
		collapsed: {
			insideCollapse: 'group tw-w-full tw-ps-8 tw-transition tw-duration-75',
			noIcon: 'tw-font-bold',
		},
		content: {
			base: 'tw-px-3 tw-flex-1 tw-whitespace-nowrap [&_p]:tw-mb-0 tw-truncate',
		},
		icon: {
			base: 'tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-500 tw-transition tw-duration-75 group-hover:tw-text-gray-900 dark:tw-text-gray-400 dark:group-hover:tw-text-white',
			active: 'tw-text-gray-700 dark:tw-text-gray-100',
		},
		label: '',
		listItem: '',
	},
	items: '',
	itemGroup:
		'tw-mt-4 tw-space-y-2 tw-border-t tw-border-gray-200 tw-pt-4 first:tw-mt-0 first:tw-border-t-0 first:tw-pt-0 dark:tw-border-gray-700 tw-ps-0   tw-text-md',
	logo: {
		base: 'tw-mb-5 tw-flex tw-items-center tw-ps-2.5',
		collapsed: {
			on: 'tw-hidden',
			off: 'tw-self-center tw-whitespace-nowrap tw-text-xl tw-font-semibold dark:tw-text-white',
		},
		img: 'tw-me-3 tw-h-6 sm:tw-h-7',
	},
};

export default theme;
