import type { FlowbiteCardTheme } from 'flowbite-react';

const theme: FlowbiteCardTheme = {
	root: {
		base: 'tw-flex tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-shadow-sm dark:tw-border-gray-700 dark:tw-bg-gray-800',
		children: 'tw-flex tw-h-full tw-flex-col tw-justify-center tw-gap-4',
		horizontal: {
			off: 'tw-flex-col',
			on: 'tw-flex-col md:tw-max-w-xl md:tw-flex-row',
		},
		href: 'hover:tw-bg-gray-100 dark:hover:tw-bg-gray-700',
	},
	img: {
		base: '',
		horizontal: {
			off: 'tw-rounded-t-lg',
			on: 'tw-h-96 tw-w-full tw-rounded-t-lg tw-object-cover md:tw-h-auto md:tw-w-48 md:tw-rounded-none md:tw-rounded-l-lg',
		},
	},
};

export default theme;
