import { PureAbility, AbilityBuilder, createMongoAbility } from '@casl/ability';
import { allResources } from './initialAbility';

export const ability = new PureAbility();

export default (action: string, subject: string) => {
	return ability.can(action, subject);
};

export const defineRulesFor = (role: any) => {
	const { can, cannot, rules } = new AbilityBuilder<PureAbility>(createMongoAbility);
	if (String(role) === 'undefined') return rules;
	can('read', 'home');
	can('read', 'profile');
	can('read', 'onboarding');
	can('read', 'not-authorized');
	if (role.enableAllResources) {
		for (const resource of allResources) {
			can('read', String(resource.id));
			if (resource.enableSave) {
				can('create', String(resource.id));
				can('update', String(resource.id));
			} else {
				cannot('create', String(resource.id));
				cannot('update', String(resource.id));
			}
			if (resource.enableDelete) {
				can('delete', String(resource.id));
			} else {
				cannot('delete', String(resource.id));
			}
		}
		return rules;
	}
	const permitted = [...role.enabledResources];
	for (const resource of permitted) {
		can('read', String(resource.resourceId));
		if (resource.allowSave) {
			can('create', String(resource.resourceId));
			can('update', String(resource.resourceId));
		} else {
			cannot('create', String(resource.resourceId));
			cannot('update', String(resource.resourceId));
		}
		if (resource.allowDelete) {
			can('delete', String(resource.resourceId));
		} else {
			cannot('delete', String(resource.resourceId));
		}
	}
	return rules;
};
