import useSessionStorageState from 'use-session-storage-state';
import { useState } from 'react';

export default function useSessionStorage(keyName: string, initialValue: any, options?: any) {
    let returnState = useState(initialValue);
    try {
        const [value, setValue] = useSessionStorageState(keyName, {
            defaultValue: initialValue,
            ...options,
        });
        returnState = [value, setValue];
    } catch (e) {
        console.error(e);
    }

    return returnState;
}
