import type { FlowbiteListGroupTheme } from 'flowbite-react';

const theme: FlowbiteListGroupTheme = {
	root: {
		base: 'tw-list-none tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-border-gray-700 dark:tw-bg-gray-800 dark:tw-text-white tw-text-end',
	},
	item: {
		base: '[&>*]:first:tw-rounded-t-lg [&>*]:last:tw-rounded-b-lg [&>*]:last:tw-border-b-0 tw-w-full tw-text-start',
		link: {
			base: 'tw-flex tw-w-full tw-border-b tw-border-gray-200 tw-py-2 tw-px-4 dark:tw-border-gray-700 tw-flex-col tw-text-start tw-cursor-pointer',
			active: {
				off: 'dark:tw-border-gray-700',
				on: 'tw-bg-primary-500 tw-text-white dark:tw-bg-gray-800',
			},
			href: {
				off: '',
				on: '',
			},
			icon: 'tw-me-2 tw-h-4 tw-w-4 tw-fill-current',
		},
	},
};
export default theme;
