import type { FlowbiteToggleSwitchTheme } from 'flowbite-react';

const theme: FlowbiteToggleSwitchTheme = {
	root: {
		base: 'tw-group tw-relative tw-flex tw-items-center tw-rounded-lg focus:tw-outline-none',
		active: {
			on: 'tw-cursor-pointer',
			off: 'tw-cursor-not-allowed tw-opacity-50',
		},
		label: 'tw-ms-3 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-white',
	},
	toggle: {
		base: 'toggle-bg tw-h-6 tw-w-11 tw-rounded-full tw-border group-focus:tw-ring-4 group-focus:tw-ring-primary-500/25',
		checked: {
			on: 'after:tw-translate-x-full after:tw-border-white tw-w-[38.5px]',
			off: 'tw-border-gray-200 tw-bg-gray-200 dark:tw-border-gray-600 dark:tw-bg-gray-700',
			color: {
				blue: 'tw-bg-blue-700 tw-border-blue-700',
				dark: 'tw-bg-dark-700 tw-border-dark-900',
				failure: 'tw-bg-red-700 tw-border-red-900',
				gray: 'tw-bg-gray-500 tw-border-gray-600',
				green: 'tw-bg-green-600 tw-border-green-700',
				light: 'tw-bg-light-700 tw-border-light-900',
				red: 'tw-bg-red-700 tw-border-red-900',
				purple: 'tw-bg-purple-700 tw-border-purple-900',
				success: 'tw-bg-green-500 tw-border-green-500',
				yellow: 'tw-bg-yellow-400 tw-border-yellow-400',
				warning: 'tw-bg-yellow-600 tw-border-yellow-600',
				cyan: 'tw-bg-cyan-500 tw-border-cyan-500',
				lime: 'tw-bg-lime-400 tw-border-lime-400',
				indigo: 'tw-bg-indigo-400 tw-border-indigo-400',
				teal: 'tw-bg-gradient-to-r tw-from-teal-400 tw-via-teal-500 tw-to-teal-600 hover:tw-bg-gradient-to-br focus:tw-ring-4',
				info: 'tw-bg-cyan-600 tw-border-cyan-600',
				pink: 'tw-bg-pink-600 tw-border-pink-600',
				primary: 'tw-bg-primary-500 tw-border-primary-500',
			},
		},
	},
};

export default theme;
