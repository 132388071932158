import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const GlobalDrawerUI = createContext<any>(null);

const GlobalDrawerContext = ({ children }: { children: React.ReactNode }) => {
	const { pathname } = useLocation();
	const [isOpen, setIsOpen] = useState(false);
	const [extra, setExtra] = useState<any>({
		titleKey: '',
		innerComponent: <></>,
		footerComponent: <></>,
	});
	const setTitleKey = (titleKey: string) => {
		setExtra((s: any) => {
			return {
				...s,
				titleKey: titleKey,
			};
		});
	};
	const setInnerComponent = (innerComponent: React.ReactNode) => {
		setExtra((s: any) => {
			return {
				...s,
				innerComponent: innerComponent,
			};
		});
	};
	const setFooterComponent = (footerComponent: React.ReactNode) => {
		setExtra((s: any) => {
			return {
				...s,
				footerComponent: footerComponent,
			};
		});
	};
	useEffect(() => {
		if (isOpen) {
			setIsOpen(false);
		}
	}, [pathname]);

	useEffect(() => {
		const element = window.document.body;
		element.classList.remove('tw-overflow-y-hidden');
		if (isOpen) {
			element.classList.add('tw-overflow-y-hidden');
		}
	}, [isOpen]);
	return (
		<GlobalDrawerUI.Provider
			value={{
				titleKey: extra.titleKey,
				innerComponent: extra.innerComponent,
				footerComponent: extra.footerComponent,
				isOpen,
				setIsOpen,
				extra,
				setExtra,
				setTitleKey,
				setInnerComponent,
				setFooterComponent,
			}}
		>
			{children}
		</GlobalDrawerUI.Provider>
	);
};

export { GlobalDrawerUI, GlobalDrawerContext };
