import type { FlowbiteAccordionTheme } from 'flowbite-react';

const theme: FlowbiteAccordionTheme = {
	root: {
		base: 'tw-divide-y tw-divide-gray-200 tw-border-gray-200 dark:tw-divide-gray-700 dark:tw-border-gray-700',
		flush: {
			off: 'tw-rounded-lg tw-border',
			on: 'tw-border-b',
		},
	},
	content: {
		base: 'tw-py-5 tw-px-5 last:tw-rounded-b-lg dark:tw-bg-gray-800 first:tw-rounded-t-lg',
	},
	title: {
		arrow: {
			base: 'tw-h-6 tw-w-6 tw-shrink-0',
			open: {
				off: '',
				on: 'tw-rotate-180',
			},
		},
		base: 'tw-flex tw-w-full tw-items-center tw-justify-between first:tw-rounded-t-lg last:tw-rounded-b-lg tw-py-5 tw-px-5 tw-text-left tw-font-medium tw-text-gray-500 dark:tw-text-gray-400',
		flush: {
			off: 'hover:tw-bg-gray-100 focus:tw-ring-4 focus:tw-ring-gray-200 dark:hover:tw-bg-gray-800 dark:focus:tw-ring-gray-700',
			on: 'tw-bg-transparent dark:tw-bg-transparent',
		},
		heading: '',
		open: {
			off: '',
			on: 'tw-text-gray-900 tw-bg-gray-100 dark:tw-bg-gray-800 dark:tw-text-white',
		},
	},
};
export default theme;
