import React from 'react';
import classNames from 'classnames';

import { Footer } from './Footer';
import { Header } from './Header';
import { HeaderSkeleton } from './HeaderSkeleton';
import { RowSkeleton } from './RowSkeleton';

import { safeString } from '@utils';

export type ITable = {
	className?: string;
	tableClassName?: string;
};

/**
 * Renders a table component with given props.
 *
 * @param {React.PropsWithChildren<ITable>} props - The props for the component.
 * @param {string} props.className - The class name for the component.
 * @param {string} props.tableClassName - The class name for the table.
 * @param {React.ReactNode} props.children - The children of the component.
 * @return {React.ReactElement} The rendered table component.
 */
const TableComponent: React.FC<React.PropsWithChildren<ITable>> = (
	props: React.PropsWithChildren<ITable>,
): React.ReactElement => {
	const { className, children, tableClassName } = props;

	return (
		<div
			className={classNames('tw-overflow-x-auto', {
				[safeString(className)]: !!className,
			})}
		>
			<table
				className={classNames(
					'tw-mb-0 tw-w-full tw-table-auto tw-caption-bottom tw-border-collapse tw-rounded-t-md tw-indent-0 tw-align-middle',
					{
						[safeString(tableClassName)]: !!tableClassName,
					},
				)}
			>
				{children}
			</table>
		</div>
	);
};

export const Table = Object.assign(TableComponent, {
	Header: Header,
	Footer: Footer,
	RowSkeleton: RowSkeleton,
	HeaderSkeleton: HeaderSkeleton,
});
