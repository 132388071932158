import type { FlowbiteBadgeTheme } from 'flowbite-react';

const theme: FlowbiteBadgeTheme = {
	root: {
		base: 'tw-flex tw-h-fit tw-items-center tw-justify-center tw-w-fit tw-gap-1 tw-font-semibold',
		color: {
			info: 'tw-bg-blue-100 tw-text-blue-800 dark:tw-bg-blue-200 dark:tw-text-blue-800 group-hover:tw-bg-blue-200 dark:group-hover:tw-bg-blue-300',
			gray: 'tw-bg-gray-100 tw-text-gray-800 dark:tw-bg-gray-700 dark:tw-text-gray-300 group-hover:tw-bg-gray-200 dark:group-hover:tw-bg-gray-600',
			failure:
				'tw-bg-red-100 tw-text-red-800 dark:tw-bg-red-200 dark:tw-text-red-900 group-hover:tw-bg-red-200 dark:group-hover:tw-bg-red-300',
			success:
				'tw-bg-green-100 tw-text-green-800 dark:tw-bg-green-200 dark:tw-text-green-900 group-hover:tw-bg-green-200 dark:group-hover:tw-bg-green-300',
			warning:
				'tw-bg-yellow-100 tw-text-yellow-800 dark:tw-bg-yellow-200 dark:tw-text-yellow-900 group-hover:tw-bg-yellow-200 dark:group-hover:tw-bg-yellow-300',
			indigo: 'tw-bg-indigo-100 tw-text-indigo-800 dark:tw-bg-indigo-200 dark:tw-text-indigo-900 group-hover:tw-bg-indigo-200 dark:group-hover:tw-bg-indigo-300',
			purple: 'tw-bg-purple-100 tw-text-purple-800 dark:tw-bg-purple-200 dark:tw-text-purple-900 group-hover:tw-bg-purple-200 dark:group-hover:tw-bg-purple-300',
			pink: 'tw-bg-pink-100 tw-text-pink-800 dark:tw-bg-pink-200 dark:tw-text-pink-900 group-hover:tw-bg-pink-200 dark:group-hover:tw-bg-pink-300',
			blue: 'tw-bg-blue-100 tw-text-blue-800 dark:tw-bg-blue-200 dark:tw-text-blue-900 group-hover:tw-bg-blue-200 dark:group-hover:tw-bg-blue-300',
			primary:
				'tw-bg-primary-100 tw-text-primary-800 dark:tw-bg-primary-200 dark:tw-text-primary-900 group-hover:tw-bg-primary-200 dark:group-hover:tw-bg-primary-300',
			cyan: 'tw-bg-cyan-100 tw-text-cyan-800 dark:tw-bg-cyan-200 dark:tw-text-cyan-900 group-hover:tw-bg-cyan-200 dark:group-hover:tw-bg-cyan-300',
			dark: 'tw-bg-gray-600 tw-text-gray-100 dark:tw-bg-gray-900 dark:tw-text-gray-200 group-hover:tw-bg-gray-500 dark:group-hover:tw-bg-gray-700',
			light: 'tw-bg-gray-200 tw-text-gray-800 dark:tw-bg-gray-400 dark:tw-text-gray-900 group-hover:tw-bg-gray-300 dark:group-hover:tw-bg-gray-500',
			green: 'tw-bg-green-100 tw-text-green-800 dark:tw-bg-green-200 dark:tw-text-green-900 group-hover:tw-bg-green-200 dark:group-hover:tw-bg-green-300',
			lime: 'tw-bg-lime-100 tw-text-lime-800 dark:tw-bg-lime-200 dark:tw-text-lime-900 group-hover:tw-bg-lime-200 dark:group-hover:tw-bg-lime-300',
			red: 'tw-bg-red-100 tw-text-red-800 dark:tw-bg-red-200 dark:tw-text-red-900 group-hover:tw-bg-red-200 dark:group-hover:tw-bg-red-300',
			teal: 'tw-bg-teal-100 tw-text-teal-800 dark:tw-bg-teal-200 dark:tw-text-teal-900 group-hover:tw-bg-teal-200 dark:group-hover:tw-bg-teal-300',
			yellow: 'tw-bg-yellow-100 tw-text-yellow-800 dark:tw-bg-yellow-200 dark:tw-text-yellow-900 group-hover:tw-bg-yellow-200 dark:group-hover:tw-bg-yellow-300',
		},
		href: 'tw-group',
		size: {
			xs: 'tw-p-1 tw-text-xs',
			sm: 'tw-p-1.5 tw-text-sm',
		},
	},
	icon: {
		off: 'tw-rounded tw-px-2 tw-py-0.5',
		on: 'tw-rounded-full tw-p-1.5',
		size: {
			xs: 'tw-w-3 tw-h-3',
			sm: 'tw-w-3.5 tw-h-3.5',
		},
	},
};
export default theme;
