import moment from 'moment';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import type { Theme } from 'react-select';
import { IMenuData, IOption } from '../types';
import { countries } from './constants/organization/websites';

export const isObjEmpty = (obj: Record<string, any>) => Object.keys(obj).length === 0;

export const extractValueOrFallback = (obj: Record<string, any>, fieldName: string, fallback: string) => {
	const value = obj[fieldName];
	if (!value) return fallback;
	if (typeof value === 'string' || typeof value === 'number') {
		return String(value);
	}
	return fallback;
};

export const selectThemeColors = (theme: Theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary25: '#fba708',
		primary: '#fba708',
		neutral10: '#fba708',
		neutral20: '#ededed',
		neutral30: '#ededed',
	},
});

export const unescapeHTML = (str: string) => {
	return str.replace(
		/&amp;|&lt;|&gt;|&#39;|&#039;|&quot;/g,
		(tag) =>
			({
				'&amp;': '&',
				'&lt;': '<',
				'&gt;': '>',
				'&#39;': "'",
				'&#039;': "'",
				'&quot;': '"',
			}[tag] || tag),
	);
};

export const shakeObject = <T = any>(obj: { [key: string]: any }, returnType: 'object' | 'entries') => {
	//TODO:: Needs refactoring or cleaning
	// const shakedObj = omitBy(omitBy(obj, isUndefined), isNull);
	// const shakedObj = omitBy(obj, isUndefined).omitBy(isNull);
	Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
	if (returnType === 'entries') {
		return Object.entries(obj);
	}
	return obj as Partial<T>;
};

export const prepareAppliedFilters = (filterEntries: any[], dictionary: any) => {
	//TODO:: Needs refactoring or cleaning
	const mappedEntries = [
		...filterEntries.filter(validateFilters).map((a: [string, any]) => {
			const [key, value] = a;
			const outputFormat = dictionary[key];
			let newValue;
			if (outputFormat === 'dateRange') {
				const [from, to] = value.map((v: any) => {
					return moment(v).format('DD.MM.YYYY');
				});
				const valuesArr = [];
				const fromStr = from;
				const toStr = to;
				if (fromStr) {
					valuesArr.push(fromStr);
				}
				if (toStr) {
					valuesArr.push(toStr);
				}
				return [key, valuesArr.join(' - ')];
			}
			switch (outputFormat) {
				case 'stringArray': {
					newValue = [...value.map((b: any) => String(b.label))].join(', ');
					break;
				}
				case 'string': {
					if (typeof value === 'object') {
						if (Array.isArray(value)) {
							newValue = String(value.map((x: any) => x.label).join(', '));
							break;
						}
						newValue = String(value.label);
						break;
					}
					newValue = String(value);
					break;
				}
				case 'number': {
					if (typeof value === 'object') {
						newValue = String(value.label);
						break;
					}
					newValue = String(Number(value));
					break;
				}
				case 'float': {
					newValue = String(Number(value).toFixed(2));
					break;
				}
				case 'dmyDate': {
					newValue = moment(value[0]).format('DD.MM.YYYY');
					break;
				}
			}
			return [key, newValue];
		}),
	];
	return mappedEntries;
};

const validateFilters = (x: [string, any]) => {
	if (Array.isArray(x.at(1))) {
		return x.at(1).length > 0;
	}
	if (['number', 'object'].indexOf(typeof x.at(1)) !== -1) {
		return !!x.at(1);
	}
	return x.at(1).length > 0;
};

export const getWebsiteStores = (
	websiteId: string,
	websiteOptions: { value: string | number; label: string; stores: IOption[] }[],
) => {
	if (!websiteId || !websiteOptions || websiteId === 'undefined' || websiteId === '-1') return [];
	const foundWebsite = websiteOptions.find((option) => safeString(option.value) === safeString(websiteId));
	if (!foundWebsite) return [];
	return foundWebsite.stores;
};

export const extractSelection = (selection: IOption[], toNumber?: boolean) => {
	return selection.map((a: IOption) => {
		if (toNumber) {
			return Number(a.value);
		}
		return String(a.value);
	});
};

export const extractFileNameFromPath = (path: string): string => {
	if (!path) return '';
	const index = path.lastIndexOf('/');
	return path.substring(index + 1);
};

export const prepareBase64 = (src: string) => {
	const index = src.indexOf(',');
	return src.substring(index + 1);
};

export const deepDiffMapper = (function () {
	return {
		VALUE_CREATED: 'created',
		VALUE_UPDATED: 'updated',
		VALUE_DELETED: 'deleted',
		VALUE_UNCHANGED: 'unchanged',
		map: function (obj1: any, obj2: any) {
			if (this.isFunction(obj1) || this.isFunction(obj2)) {
				console.error('Invalid argument. Function given, object expected.');
			}
			if (this.isValue(obj1) || this.isValue(obj2)) {
				if (this.compareValues(obj1, obj2) !== this.VALUE_UNCHANGED) {
					return {
						type: this.compareValues(obj1, obj2),
						data: [obj1, obj2],
					};
				}
				return {};
			}

			const diff: Record<string, any> = {};
			for (const key in obj1) {
				if (this.isFunction(obj1[key])) {
					continue;
				}

				let value2 = undefined;

				if (obj2[key] !== undefined) {
					value2 = obj2[key];
				}

				diff[key] = this.map(obj1[key], value2);
			}
			for (const key in obj2) {
				if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
					continue;
				}

				diff[key] = this.map(undefined, obj2[key]);
			}

			return diff;
		},
		compareValues: function (value1: any, value2: any) {
			if (value1 === value2) {
				return this.VALUE_UNCHANGED;
			}
			if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
				return this.VALUE_UNCHANGED;
			}
			if (value1 === undefined) {
				return this.VALUE_CREATED;
			}
			if (value2 === undefined) {
				return this.VALUE_DELETED;
			}
			return this.VALUE_UPDATED;
		},
		isFunction: function (x: any) {
			return Object.prototype.toString.call(x) === '[object Function]';
		},
		isArray: function (x: any) {
			return Object.prototype.toString.call(x) === '[object Array]';
		},
		isDate: function (x: any) {
			return Object.prototype.toString.call(x) === '[object Date]';
		},
		isObject: function (x: any) {
			return Object.prototype.toString.call(x) === '[object Object]';
		},
		isBoolean: function (x: any) {
			return Object.prototype.toString.call(x) === '[object Boolean]';
		},
		isValue: function (x: any) {
			return !this.isObject(x) && !this.isArray(x);
		},
	};
})();

export const flattenObject = (ob: any) => {
	const toReturn: any = {};

	for (const i in ob) {
		if (typeof ob[i] == 'object' && ob[i] !== null) {
			const flatObject = flattenObject(ob[i]);
			for (const x in flatObject) {
				toReturn[i + '.' + x] = flatObject[x];
			}
		} else {
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
};

export const base64toFile = (base64: string, filename: string) => {
	const arr: any[] = base64.split(',');
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
};

export const prepareImageUrl = (url: string) => {
	return String(url).substring(String(url).indexOf('.com/') + 4);
};

export const isPercentage = (val: string) => typeof val === 'string' && val.indexOf('%') > -1;

export const percentToPx = (value: any, comparativeValue: number) => {
	if (value.indexOf('px') > -1 || value === 'auto' || !comparativeValue) return value;
	const percent = parseInt(value);
	return (percent / 100) * comparativeValue + 'px';
};
export const pxToPercent = (value: any, comparativeValue: number) => {
	const val = (Math.abs(value) / comparativeValue) * 100;
	if (value < 0) return -1 * val;
	else return Math.round(val);
};
export const getElementDimensions = (element: HTMLElement) => {
	const computedStyle = getComputedStyle(element);

	let height = element.clientHeight,
		width = element.clientWidth; // width with padding

	height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
	width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

	return {
		width,
		height,
	};
};

export const safeString = (value: any) => {
	if (value === 'null' || value === 'undefined') return '';
	if (typeof value === 'number' && value === 0) return '0';
	return value ? String(value) : '';
};

export const safeArray = <T>(value: undefined | T[]) => {
	if (!value) return [];
	if (Array.isArray(value)) {
		return value.filter(Boolean);
	}
	return [];
};

export const dataURLtoFile = (dataurl: string, filename: string): File => {
	const arr = dataurl.split(',');
	const mime = arr[0].match(/:(.*?);/)![1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
};

export const downloadFile = (fileName: string, blob: Blob) => {
	const link = document.createElement('a');
	const url = URL.createObjectURL(blob);

	link.href = url;
	link.download = fileName;
	link.click();

	URL.revokeObjectURL(url);
	link.remove();
};

export const generateRandomString = (length = 5): string => {
	return (Math.random() + 1).toString(36).slice(-length);
};

/**
 * Clears any falsy values from the input array and returns a new array with
 * only the truthy values.
 *
 * ! NOTE:
 * ! While this function is useful, it is not the most efficient way to clear
 * ! an array of falsy values. eg. Number arrays that have 0.
 *
 * @param {T[]} clearingArray - The input array to be cleared of falsy values.
 * @return {T[]} A new array containing only the truthy values of the input array.
 */
export const clearArray = <T>(clearingArray: T[]): T[] => {
	if (!clearingArray) return [];
	return clearingArray.filter(Boolean);
};

export const isNavLinkActive = (link: string, currentURL: string, isParent = false) => {
	if (isParent) {
		return currentURL === link;
	}
	return currentURL === link || currentURL.includes(link);
};
export const hasActiveChild = (item: IMenuData, currentUrl: string) => {
	const { children } = item;

	if (!children) {
		return false;
	}

	for (const child of children) {
		if (
			child &&
			child.navLink &&
			currentUrl &&
			(child.navLink === currentUrl || currentUrl.includes(child.navLink))
		) {
			return true;
		}
	}

	return false;
};

export const clearAllCookies = () => {
	const cookies = document.cookie.split('; ');
	for (let c = 0; c < cookies.length; c++) {
		const domain = window.location.hostname.split('.');
		while (domain.length > 0) {
			const cookieBase =
				encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
				'=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
				domain.join('.') +
				' ;path=';
			const pathname = location.pathname.split('/');
			document.cookie = cookieBase + '/';
			while (pathname.length > 0) {
				document.cookie = cookieBase + pathname.join('/');
				pathname.pop();
			}
			domain.shift();
		}
	}
};

export const cyrb53 = (str: string, seed = 1) => {
	let h1 = 0xdeadbeef ^ seed,
		h2 = 0x41c6ce57 ^ seed;
	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}
	h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
	h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
	h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
	h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

	return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const extractExtension = (fileName: string): string => {
	const parts = fileName.split('.').slice(1);
	if (parts.length >= 2) {
		return parts.join('.');
	} else if (parts.length === 1) {
		return parts[0];
	} else {
		return '';
	}
};

export function remToPx(value: number) {
	if (typeof document === 'undefined') {
		return value * 16;
	}

	return value * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
export const isValidUrl = (urlString: string) => {
	const urlPattern = new RegExp(
		'^(https?:\\/\\/)?' + // validate protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
			'(\\#[-a-z\\d_]*)?$',
		'i',
	); // validate fragment locator
	return !!urlPattern.test(urlString);
};

export const capitalizeFirstLetter = (str: string): string => {
	const firstLetter = str.charAt(0);
	const firstLetterCap = firstLetter.toUpperCase();
	const remainingLetters = str.slice(1);
	const capitalizedWord = firstLetterCap + remainingLetters;
	return capitalizedWord;
};
