import React from 'react';

import { Skeleton } from 'antd';

import { generateRandomString } from '@utils';

export type IRowsSkeleton = {
	columnsCount: number;
	rowsCount?: number;
};

/**
 * Renders a skeleton table row with specified number of columns and rows.
 *
 * @param {ITableRowsSkeleton} props - The component props object.
 * @param {number} props.columnsCount - The number of columns in each row.
 * @param {number} props.rowsCount - The number of rows to be rendered.
 * @return {React.ReactElement} A skeleton table row component.
 */
export const RowSkeleton: React.FC<IRowsSkeleton> = (props: IRowsSkeleton): React.ReactElement => {
	const { columnsCount, rowsCount = 12 } = props;
	return (
		<>
			{new Array(rowsCount).fill('').map(() => {
				return (
					<tr key={generateRandomString(6)} className="tw-h-[60px]">
						{new Array(columnsCount).fill('').map(() => {
							return (
								<td
									key={generateRandomString(6)}
									className="tw-flex-auto tw-border-b tw-border-gray-200 tw-bg-white tw-px-8 tw-text-center dark:tw-border-gray-700 dark:tw-bg-gray-800"
								>
									<Skeleton paragraph={false} active title className="tw-mt-2" />
								</td>
							);
						})}
					</tr>
				);
			})}
		</>
	);
};
