import type { FlowbiteTextInputTheme } from 'flowbite-react';

const theme: FlowbiteTextInputTheme = {
	base: 'tw-flex',
	addon: 'tw-inline-flex tw-items-center tw-rounded-s-md tw-border tw-border-e-0 tw-border-gray-300 tw-bg-gray-200 tw-px-3 tw-text-sm tw-text-gray-900 dark:tw-border-gray-700 dark:tw-bg-gray-600 dark:tw-text-gray-400',
	field: {
		base: 'tw-relative tw-w-full',
		icon: {
			base: 'tw-pointer-events-none tw-absolute tw-inset-y-0 tw-start-0 tw-flex tw-items-center tw-ps-3',
			svg: 'tw-h-5 tw-w-5 tw-text-gray-500 dark:tw-text-gray-400',
		},
		rightIcon: {
			base: 'tw-pointer-events-none tw-absolute tw-inset-y-0 tw-end-0 tw-flex tw-items-center tw-pe-3',
			svg: 'tw-h-5 tw-w-5 tw-text-gray-500 dark:tw-text-gray-400',
		},
		input: {
			base: 'tw-block tw-caret-primary tw-w-full tw-border disabled:tw-cursor-not-allowed disabled:tw-opacity-50 tw-overscroll-none tw-rounded-lg',
			sizes: {
				sm: 'tw-p-2 sm:tw-text-xs',
				md: 'tw-p-2.5 tw-text-sm',
				lg: 'sm:tw-text-md tw-p-4',
			},
			colors: {
				gray: 'tw-bg-gray-50 tw-border-gray-200 tw-text-gray-600 focus:tw-border-primary-500 focus:tw-ring-primary-500 dark:tw-border-gray-700 dark:tw-bg-gray-800 dark:tw-text-white dark:tw-placeholder-gray-400 dark:focus:tw-border-primary-500 dark:focus:tw-ring-primary-500',
				info: 'tw-border-cyan-500 tw-bg-cyan-50 tw-text-cyan-900 tw-placeholder-cyan-700 focus:tw-border-cyan-500 focus:tw-ring-cyan-500 dark:tw-border-cyan-400 dark:tw-bg-cyan-100 dark:focus:tw-border-cyan-500 dark:focus:tw-ring-cyan-500',
				failure:
					'tw-border-red-500 tw-bg-red-50 tw-text-red-900 tw-placeholder-red-500 focus:tw-border-red-500 focus:tw-ring-red-500 dark:tw-border-red-500 dark:tw-bg-gray-800 dark:focus:tw-border-red-500 dark:focus:tw-ring-red-500',
				warning:
					'tw-border-orange-500 tw-bg-orange-50 tw-text-orange-900 tw-placeholder-orange-700 focus:tw-border-orange-500 focus:tw-ring-orange-500 dark:tw-border-orange-400 dark:tw-bg-orange-100 dark:focus:tw-border-orange-500 dark:focus:tw-ring-orange-500',
				success:
					'tw-border-green-500 tw-bg-green-50 tw-text-green-900 tw-placeholder-green-700 focus:tw-border-green-500 focus:tw-ring-green-500 dark:tw-border-green-400 dark:tw-bg-green-100 dark:focus:tw-border-green-500 dark:focus:tw-ring-green-500',
				primary:
					'tw-border-primary-500 tw-bg-primary-50 tw-text-primary-900 tw-placeholder-primary-700 focus:tw-border-primary-500 focus:tw-ring-primary-500 dark:tw-border-primary-400 dark:tw-bg-primary-100 dark:focus:tw-border-primary-500 dark:focus:tw-ring-primary-500',
			},
			withRightIcon: {
				on: 'tw-pe-10',
				off: '',
			},
			withIcon: {
				on: 'tw-ps-10',
				off: '',
			},
			withAddon: {
				on: 'tw-rounded-e-lg',
				off: 'tw-rounded-lg',
			},
			withShadow: {
				on: 'tw-shadow-sm dark:tw-shadow-sm-light',
				off: '',
			},
		},
	},
};

export default theme;
