export const initialAbility = [
    {
        action: 'read',
        subject: 'Login',
    },
];

export const allResources = [
    {
        id: 'submissions',
        createdAt: '2022-08-28T11:01:58.268Z',
        updatedAt: '2023-03-09T13:10:16.748Z',
        displayOrder: 0,
        name: 'Submissions',
        enableSave: true,
        enableDelete: false,
        parentId: null,
    },
    {
        id: 'users',
        createdAt: '2022-08-28T11:02:01.645Z',
        updatedAt: '2023-03-09T13:10:16.764Z',
        displayOrder: 1,
        name: 'Users',
        enableSave: true,
        enableDelete: true,
        parentId: null,
    },
    {
        id: 'user-roles',
        createdAt: '2022-08-28T11:02:02.930Z',
        updatedAt: '2023-03-09T13:10:16.773Z',
        displayOrder: 2,
        name: 'User Roles',
        enableSave: true,
        enableDelete: true,
        parentId: null,
    },
    {
        id: 'organization',
        createdAt: '2022-08-28T11:02:04.067Z',
        updatedAt: '2023-03-09T13:10:16.778Z',
        displayOrder: 3,
        name: 'Organization',
        enableSave: false,
        enableDelete: false,
        parentId: null,
    },
    {
        id: 'organization_organizations',
        createdAt: '2022-08-28T11:02:05.369Z',
        updatedAt: '2023-03-09T13:10:16.786Z',
        displayOrder: 4,
        name: 'Organizations',
        enableSave: true,
        enableDelete: false,
        parentId: 'organization',
    },
    {
        id: 'organization_brands',
        createdAt: '2022-08-28T11:02:07.085Z',
        updatedAt: '2023-03-09T13:10:16.793Z',
        displayOrder: 5,
        name: 'Brands',
        enableSave: true,
        enableDelete: true,
        parentId: 'organization',
    },
    {
        id: 'organization_branches',
        createdAt: '2022-08-28T11:02:08.218Z',
        updatedAt: '2023-03-09T13:10:16.801Z',
        displayOrder: 6,
        name: 'Branches',
        enableSave: true,
        enableDelete: true,
        parentId: 'organization',
    },
    {
        id: 'organization_websites',
        createdAt: '2022-10-10T14:48:20.160Z',
        updatedAt: '2023-03-09T13:10:16.810Z',
        displayOrder: 7,
        name: 'Websites',
        enableSave: true,
        enableDelete: false,
        parentId: 'organization',
    },
    {
        id: 'organization_designs',
        createdAt: '2022-10-10T14:48:20.171Z',
        updatedAt: '2023-03-09T13:10:16.817Z',
        displayOrder: 8,
        name: 'Designs',
        enableSave: true,
        enableDelete: false,
        parentId: 'organization',
    },
    {
        id: 'organization_cuisines',
        createdAt: '2022-08-28T11:02:09.086Z',
        updatedAt: '2023-03-09T13:10:16.830Z',
        displayOrder: 9,
        name: 'Cuisines',
        enableSave: true,
        enableDelete: true,
        parentId: 'organization',
    },
    {
        id: 'organization_city',
        createdAt: '2022-08-28T11:02:10.362Z',
        updatedAt: '2023-03-09T13:10:16.837Z',
        displayOrder: 10,
        name: 'City',
        enableSave: true,
        enableDelete: true,
        parentId: 'organization',
    },
    {
        id: 'organization_district',
        createdAt: '2022-08-28T11:02:11.090Z',
        updatedAt: '2023-03-09T13:10:16.846Z',
        displayOrder: 11,
        name: 'District',
        enableSave: true,
        enableDelete: true,
        parentId: 'organization',
    },
    {
        id: 'orders',
        createdAt: '2022-08-28T11:02:12.522Z',
        updatedAt: '2023-03-09T13:10:16.852Z',
        displayOrder: 12,
        name: 'Orders',
        enableSave: false,
        enableDelete: false,
        parentId: null,
    },
    {
        id: 'order_reorder',
        createdAt: '2022-08-28T11:02:13.950Z',
        updatedAt: '2023-03-09T13:10:16.857Z',
        displayOrder: 13,
        name: 'Reorder',
        enableSave: false,
        enableDelete: false,
        parentId: 'orders',
    },
    {
        id: 'order_cancel',
        createdAt: '2022-08-28T11:02:15.083Z',
        updatedAt: '2023-03-09T13:10:16.865Z',
        displayOrder: 14,
        name: 'Cancel',
        enableSave: false,
        enableDelete: false,
        parentId: 'orders',
    },
    {
        id: 'order_credit-memos',
        createdAt: '2022-08-28T11:02:15.956Z',
        updatedAt: '2023-03-09T13:10:16.873Z',
        displayOrder: 15,
        name: 'Credit Memos',
        enableSave: false,
        enableDelete: false,
        parentId: 'orders',
    },
    {
        id: 'order_create-invoice',
        createdAt: '2022-08-28T11:02:17.042Z',
        updatedAt: '2023-03-09T13:10:16.879Z',
        displayOrder: 16,
        name: 'Create Invoice',
        enableSave: false,
        enableDelete: false,
        parentId: 'orders',
    },
    {
        id: 'order_hold',
        createdAt: '2022-08-28T11:02:18.221Z',
        updatedAt: '2023-03-09T13:10:16.884Z',
        displayOrder: 17,
        name: 'Hold',
        enableSave: false,
        enableDelete: false,
        parentId: 'orders',
    },
    {
        id: 'order_unhold',
        createdAt: '2022-08-28T11:02:19.636Z',
        updatedAt: '2023-03-09T13:10:16.891Z',
        displayOrder: 18,
        name: 'Unhold',
        enableSave: false,
        enableDelete: false,
        parentId: 'orders',
    },
    {
        id: 'order_ship',
        createdAt: '2022-08-28T11:02:21.043Z',
        updatedAt: '2023-03-09T13:10:16.898Z',
        displayOrder: 19,
        name: 'Ship',
        enableSave: false,
        enableDelete: false,
        parentId: 'orders',
    },
    {
        id: 'order_comment',
        createdAt: '2022-08-28T11:02:22.040Z',
        updatedAt: '2023-03-09T13:10:16.906Z',
        displayOrder: 20,
        name: 'Comment',
        enableSave: true,
        enableDelete: true,
        parentId: 'orders',
    },
    {
        id: 'order_order-history',
        createdAt: '2022-08-28T11:02:23.185Z',
        updatedAt: '2023-03-09T13:10:16.914Z',
        displayOrder: 21,
        name: 'Order History',
        enableSave: false,
        enableDelete: false,
        parentId: 'orders',
    },
    {
        id: 'order_send-order-email',
        createdAt: '2022-08-28T11:02:24.657Z',
        updatedAt: '2023-03-09T13:10:16.920Z',
        displayOrder: 22,
        name: 'Send Order Email',
        enableSave: false,
        enableDelete: false,
        parentId: 'orders',
    },
    {
        id: 'order_send-sales-email',
        createdAt: '2022-08-28T11:02:25.970Z',
        updatedAt: '2023-03-09T13:10:16.928Z',
        displayOrder: 23,
        name: 'Send Sales Email',
        enableSave: false,
        enableDelete: false,
        parentId: 'orders',
    },
    {
        id: 'audit',
        createdAt: '2022-08-28T11:02:27.382Z',
        updatedAt: '2023-03-09T13:10:16.934Z',
        displayOrder: 24,
        name: 'Audit',
        enableSave: false,
        enableDelete: false,
        parentId: null,
    },
    {
        id: 'audit_action-log',
        createdAt: '2022-08-28T11:02:28.861Z',
        updatedAt: '2023-03-09T13:10:16.942Z',
        displayOrder: 25,
        name: 'Action Log',
        enableSave: false,
        enableDelete: false,
        parentId: 'audit',
    },
    {
        id: 'audit_login-attempts',
        createdAt: '2022-08-28T11:02:30.319Z',
        updatedAt: '2023-03-09T13:10:16.948Z',
        displayOrder: 26,
        name: 'Login Attempts',
        enableSave: false,
        enableDelete: false,
        parentId: 'audit',
    },
    {
        id: 'audit_active-session',
        createdAt: '2022-08-28T11:02:32.269Z',
        updatedAt: '2023-03-09T13:10:16.954Z',
        displayOrder: 27,
        name: 'Actiove Session',
        enableSave: true,
        enableDelete: false,
        parentId: 'audit',
    },
    {
        id: 'catalog',
        createdAt: '2022-08-28T11:02:33.692Z',
        updatedAt: '2023-03-09T13:10:16.963Z',
        displayOrder: 28,
        name: 'Catalog',
        enableSave: false,
        enableDelete: false,
        parentId: null,
    },
    {
        id: 'catalog_products',
        createdAt: '2022-08-28T11:02:34.415Z',
        updatedAt: '2023-03-09T13:10:16.969Z',
        displayOrder: 29,
        name: 'Products',
        enableSave: true,
        enableDelete: true,
        parentId: 'catalog',
    },
    {
        id: 'catalog_categories',
        createdAt: '2022-08-28T11:02:35.144Z',
        updatedAt: '2023-03-09T13:10:16.977Z',
        displayOrder: 30,
        name: 'Categories',
        enableSave: true,
        enableDelete: true,
        parentId: 'catalog',
    },
    {
        id: 'dashboard',
        createdAt: '2022-08-28T11:02:36.150Z',
        updatedAt: '2023-03-09T13:10:16.985Z',
        displayOrder: 31,
        name: 'Dashboard',
        enableSave: true,
        enableDelete: false,
        parentId: null,
    },
    {
        id: 'marketing',
        createdAt: '2022-08-28T11:02:37.289Z',
        updatedAt: '2023-03-09T13:10:16.993Z',
        displayOrder: 32,
        name: 'Marketing',
        enableSave: true,
        enableDelete: true,
        parentId: null,
    },
    {
        id: 'item_availability',
        createdAt: '2023-02-28T09:55:36.195Z',
        updatedAt: '2023-03-09T13:10:16.999Z',
        displayOrder: 33,
        name: 'Item Availability',
        enableSave: true,
        enableDelete: false,
        parentId: null,
    },
    {
        id: 'cms_pages',
        createdAt: '2023-02-28T09:55:36.201Z',
        updatedAt: '2023-03-09T13:10:17.006Z',
        displayOrder: 34,
        name: 'Cms Pages',
        enableSave: true,
        enableDelete: true,
        parentId: null,
    },
    {
        id: 'cms_templates',
        createdAt: '2023-02-28T09:55:36.208Z',
        updatedAt: '2023-03-09T13:10:17.017Z',
        displayOrder: 35,
        name: 'Cms Templates',
        enableSave: true,
        enableDelete: true,
        parentId: null,
    },
    {
        id: 'availability-rules',
        createdAt: '2023-05-04T09:55:36.201Z',
        updatedAt: '2023-05-04T118:00:17.006Z',
        displayOrder: 36,
        name: 'Availability Rules',
        enableSave: true,
        enableDelete: true,
        parentId: null,
    },
];

export const _ = undefined;
