import type { FlowbiteButtonTheme } from 'flowbite-react';

const theme: FlowbiteButtonTheme = {
	base: 'tw-group tw-flex tw-h-min tw-items-center tw-justify-center tw-p-0.5 tw-text-center tw-font-medium focus:tw-z-10 focus:tw-outline-none',
	fullSized: 'tw-w-full',
	color: {
		dark: 'tw-text-white tw-bg-gray-800 tw-border tw-border-transparent enabled:hover:tw-bg-gray-900 focus:tw-ring-4 focus:tw-ring-gray-300 dark:tw-bg-gray-800 dark:enabled:hover:tw-bg-gray-700 dark:focus:tw-ring-gray-800 dark:tw-border-gray-700',
		failure:
			'tw-text-white tw-bg-red-700 tw-border tw-border-transparent enabled:hover:tw-bg-red-800 focus:tw-ring-4 focus:tw-ring-red-300 dark:tw-bg-red-600 dark:enabled:hover:tw-bg-red-700 dark:focus:tw-ring-red-900',
		gray: 'tw-text-gray-600 tw-bg-white tw-border tw-border-gray-200 enabled:hover:tw-bg-gray-100 focus:tw-ring-primary-500 dark:tw-bg-transparent dark:tw-text-gray-400 dark:tw-border-gray-700 dark:enabled:hover:tw-text-white dark:enabled:hover:tw-bg-gray-700 focus:tw-ring-2',
		info: 'tw-text-white tw-bg-blue-700 tw-border tw-border-transparent enabled:hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-ring-blue-300 dark:tw-bg-blue-600 dark:enabled:hover:tw-bg-blue-700 dark:focus:tw-ring-blue-800',
		light: 'tw-text-gray-900 tw-bg-white tw-border tw-border-gray-300 enabled:hover:tw-bg-gray-100 focus:tw-ring-4 focus:tw-ring-cyan-300 focus:tw-bg-gray-600 dark:tw-text-white dark:tw-border-gray-700 dark:enabled:hover:tw-bg-gray-700 dark:enabled:hover:tw-border-gray-700 dark:focus:tw-ring-gray-700',
		purple: 'tw-text-white tw-bg-purple-700 tw-border tw-border-transparent enabled:tw-hover:bg-purple-800 focus:tw-ring-4 focus:tw-ring-purple-300 dark:tw-bg-purple-600 dark:enabled:hover:tw-bg-purple-700 dark:focus:tw-ring-purple-900',
		success:
			'tw-text-white tw-bg-green-700 tw-border tw-border-transparent enabled:hover:tw-bg-green-800 focus:tw-ring-4 focus:tw-ring-green-300 dark:tw-bg-green-600 dark:enabled:hover:tw-bg-green-700 dark:focus:tw-ring-green-800',
		primary:
			'tw-text-white tw-bg-primary-500 tw-border tw-border-transparent enabled:hover:tw-bg-primary-600 focus:tw-ring-4 focus:tw-ring-primary-300 dark:tw-bg-primary-500 dark:enabled:hover:tw-bg-primary-600 dark:focus:tw-ring-primary-700',
		warning:
			'tw-text-white tw-bg-orange-400 tw-border tw-border-transparent enabled:hover:tw-bg-orange-500 focus:tw-ring-4 focus:tw-ring-orange-300 dark:focus:tw-ring-orange-900',
		blue: 'tw-text-blue-900 tw-bg-white border tw-border-blue-300 enabled:hover:tw-bg-blue-100 focus:tw-ring-4 focus:tw-ring-blue-300 focus:tw-bg-blue-600 dark:text-white dark:tw-border-blue-600 dark:enabled:hover:tw-bg-blue-700 dark:enabled:hover:tw-border-blue-700 dark:focus:tw-ring-blue-700',
		cyan: 'tw-text-cyan-900 tw-bg-white tw-border tw-border-cyan-300 enabled:hover:tw-bg-cyan-100 focus:tw-ring-4 focus:tw-ring-cyan-300 focus:tw-bg-cyan-600 dark:tw-text-white dark:tw-border-cyan-600 dark:enabled:hover:tw-bg-cyan-700 dark:enabled:hover:tw-border-cyan-700 dark:focus:tw-ring-cyan-700',
		green: 'tw-text-green-900 tw-bg-white tw-border tw-border-green-300 enabled:hover:tw-bg-green-100 focus:tw-ring-4 focus:tw-ring-green-300 focus:tw-bg-green-600 dark:tw-text-white dark:tw-border-green-600 dark:enabled:hover:tw-bg-green-700 dark:enabled:hover:tw-border-green-700 dark:focus:tw-ring-green-700',
		indigo: 'tw-text-indigo-900 tw-bg-white tw-border tw-border-indigo-300 enabled:hover:tw-bg-indigo-100 focus:tw-ring-4 focus:tw-ring-indigo-300 focus:tw-bg-indigo-600 dark:tw-text-white dark:tw-border-indigo-600 dark:enabled:hover:tw-bg-indigo-700 dark:enabled:hover:tw-border-indigo-700 dark:focus:tw-ring-indigo-700',
		lime: 'tw-text-lime-900 tw-bg-white tw-border tw-border-lime-300 enabled:hover:tw-bg-lime-100 focus:tw-ring-4 focus:tw-ring-lime-300 focus:tw-bg-lime-600 dark:tw-text-white dark:tw-border-lime-600 dark:enabled:hover:tw-bg-lime-700 dark:enabled:hover:tw-border-lime-700 dark:focus:tw-ring-lime-700',
		pink: 'tw-text-pink-900 tw-bg-white tw-border tw-border-pink-300 enabled:hover:tw-bg-pink-100 focus:tw-ring-4 focus:tw-ring-pink-300 focus:tw-bg-pink-600 dark:tw-text-white dark:tw-border-pink-600 dark:enabled:hover:tw-bg-pink-700 dark:enabled:hover:tw-border-pink-700 dark:focus:tw-ring-pink-700',
		red: 'tw-text-red-900 tw-bg-white tw-border tw-border-red-300 enabled:hover:tw-bg-red-100 focus:tw-ring-4 focus:tw-ring-red-300 focus:tw-bg-red-600 dark:tw-text-white dark:tw-border-red-600 dark:enabled:hover:tw-bg-red-700 dark:enabled:hover:tw-border-red-700 dark:focus:tw-ring-red-700',
		teal: 'tw-text-teal-900 twbg-white tw-border tw-border-teal-300 enabled:hover:tw-bg-teal-100 focus:tw-ring-4 focus:tw-ring-teal-300 focus:tw-bg-teal-600 dark:tw-text-white dark:tw-border-teal-600 dark:enabled:hover:tw-bg-teal-700 dark:enabled:hover:tw-border-teal-700 dark:focus:tw-ring-teal-700',
		yellow: 'tw-text-yellow-900 tw-bg-white tw-border tw-border-yellow-300 enabled:hover:tw-bg-yellow-100 focus:tw-ring-4 focus:tw-ring-yellow-300 focus:tw-bg-yellow-600 dark:tw-text-white dark:tw-border-yellow-600 dark:enabled:hover:tw-bg-yellow-700 dark:enabled:hover:tw-border-yellow-700 dark:focus:tw-ring-yellow-700',
	},
	disabled: 'tw-cursor-not-allowed tw-opacity-50',
	isProcessing: 'tw-cursor-wait',
	spinnerSlot: 'tw-me-3',
	gradient: {
		cyan: 'text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800',
		failure:
			'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800',
		info: 'text-white bg-gradient-to-r from-cyan-500 via-cyan-600 to-cyan-700 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800 ',
		lime: 'text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800',

		pink: 'text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-pink-300 dark:focus:ring-pink-800',
		purple: 'text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-800',
		success:
			'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800',
		teal: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-teal-300 dark:focus:ring-teal-800',
	},
	gradientDuoTone: {
		cyanToBlue:
			'text-white bg-gradient-to-r from-cyan-500 to-cyan-500 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800',
		greenToBlue:
			'text-white bg-gradient-to-br from-green-400 to-cyan-600 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-green-200 dark:focus:ring-green-800',
		pinkToOrange:
			'text-white bg-gradient-to-br from-pink-500 to-orange-400 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800',
		purpleToBlue:
			'text-white bg-gradient-to-br from-purple-600 to-cyan-500 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800',
		purpleToPink:
			'text-white bg-gradient-to-r from-purple-500 to-pink-500 enabled:hover:bg-gradient-to-l focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-800',
		redToYellow:
			'text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-red-100 dark:focus:ring-red-400',
		tealToLime:
			'text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 enabled:hover:bg-gradient-to-l enabled:hover:from-teal-200 enabled:hover:to-lime-200 enabled:hover:text-gray-900 focus:ring-4 focus:ring-lime-200 dark:focus:ring-teal-700',
	},
	inner: {
		base: 'tw-flex tw-items-center',
		position: {
			none: '',
			start: 'tw-rounded-e-none',
			middle: 'tw-rounded-none',
			end: 'tw-rounded-s-none',
		},
		outline: 'tw-border tw-border-transparent',
	},
	label: 'tw-me-2 tw-inline-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full tw-bg-cyan-200 tw-text-xs tw-font-semibold tw-text-cyan-800',
	outline: {
		color: {
			gray: 'tw-border tw-border-gray-900 dark:tw-border-white',
			default: 'tw-border-0',
			light: '',
			primary: 'tw-border-0 tw-border-primary-400 hover:tw-text-primary',
		},
		off: '',
		on: 'tw-text-primary-500 group-disabled:hover:tw-text-primary hover:tw-text-white tw-flex tw-justify-center tw-bg-white tw-text-gray-900 tw-transition-all tw-duration-75 tw-ease-in group-enabled:group-hover:tw-bg-opacity-0 group-enabled:group-hover:tw-text-inherit dark:tw-bg-gray-800 tw-w-full',
		pill: {
			off: 'tw-rounded-md',
			on: 'tw-rounded-full',
		},
	},
	pill: {
		off: 'tw-rounded-lg',
		on: 'tw-rounded-full',
	},
	size: {
		xs: 'tw-text-xs tw-px-2 tw-py-1',
		sm: 'tw-text-sm tw-px-3 tw-py-1.5',
		md: 'tw-text-sm tw-px-4 tw-py-2',
		'md-p-0': 'tw-text-sm tw-p-0',
		lg: 'tw-text-base tw-px-5 tw-py-2.5',
		xl: 'tw-text-base tw-px-6 tw-py-3',
	},
};

export default theme;
