import React, { useEffect, useMemo } from 'react';
import { Toaster, toast, useToasterStore } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button } from 'flowbite-react';

import { safeString } from '@utils';

const ToasterDiv: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const { toasts } = useToasterStore();

	const showClearAll = useMemo(() => {
		if (
			toasts.length > 0 &&
			toasts.filter((toast) => toast.visible).filter((toast) => safeString(toast.id) !== 'clearAllNotifications')
				.length > 3
		) {
			return true;
		}
		return false;
	}, [toasts.map((toast) => safeString(toast.id)).join(',')]);

	useEffect(() => {
		if (showClearAll) {
			toast(
				<Button color="primary" size="sm" onClick={() => toast.dismiss()}>
					{t('clearAllNotifications')}
				</Button>,
				{
					id: 'clearAllNotifications',
					duration: Infinity,
					position: 'top-center',
					className: 'tw-p-0 tw-m-0 [&_div]:tw-m-0',
				},
			);
		} else {
			toast.dismiss('clearAllNotifications');
		}
	}, [showClearAll]);

	return <Toaster position={'top-right'} toastOptions={{ className: 'react-hot-toast' }} />;
};
export default ToasterDiv;
