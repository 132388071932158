import type { FlowbiteAlertTheme } from 'flowbite-react';

const theme: FlowbiteAlertTheme = {
	base: 'tw-flex tw-flex-col tw-gap-2 tw-p-4 tw-text-sm',
	borderAccent: 'tw-border-t-4',
	closeButton: {
		base: '-tw-mx-1.5 -tw-my-1.5 tw-ms-auto tw-inline-flex tw-h-8 tww-8 tw-rounded-lg tw-p-1.5 focus:tw-ring-2',
		icon: 'tw-w-5 tw-h-5',
		color: {
			info: 'tw-bg-blue-100 twtext-blue-500 hover:tw-bg-blue-200 focus:tw-ring-blue-400 dark:tw-bg-blue-200 dark:tw-text-blue-600 dark:hover:tw-bg-blue-300',
			gray: 'tw-bg-gray-100 tw-text-gray-500 hover:tw-bg-gray-200 focus:tw-ring-gray-400 dark:twbg-gray-700 dark:tw-text-gray-300 dark:hover:tw-bg-gray-800 dark:hover:tw-text-white',
			failure:
				'tw-bg-red-100 tw-text-red-500 hover:tw-bg-red-200 focus:tw-ring-red-400 dark:tw-bg-red-200 dark:tw-text-red-600 dark:hover:tw-bg-red-300',
			success:
				'tw-bg-green-100 tw-text-green-500 hover:tw-bg-green-200 focus:tw-ring-green-400 dark:tw-bg-green-200 dark:tw-text-green-600 dark:hover:tw-bg-green-300',
			warning:
				'tw-bg-yellow-100 tw-text-yellow-500 hover:tw-bg-yellow-200 focus:tw-ring-yellow-400 dark:tw-bg-yellow-200 dark:tw-text-yellow-600 dark:hover:tw-bg-yellow-300',
			red: 'tw-bg-red-100 tw-text-red-500 hover:tw-bg-red-200 focus:tw-ring-red-400 dark:tw-bg-red-200 dark:tw-text-red-600 dark:hover:tw-bg-red-300',
			green: 'tw-bg-green-100 tw-text-green-500 hover:tw-bg-green-200 focus:tw-ring-green-400 dark:tw-bg-green-200 dark:tw-text-green-600 dark:hover:tw-bg-green-300',
			yellow: 'tw-bg-yellow-100 tw-text-yellow-500 hover:tw-bg-yellow-200 focus:tw-ring-yellow-400 dark:tw-bg-yellow-200 dark:tw-text-yellow-600 dark:hover:tw-bg-yellow-300',
			blue: 'tw-bg-blue-100 tw-text-blue-500 hover:tw-bg-blue-200 focus:tw-ring-blue-400 dark:tw-bg-blue-200 dark:tw-text-blue-600 dark:hover:tw-bg-blue-300',
			cyan: 'tw-bg-cyan-100 tw-text-cyan-500 hover:tw-bg-cyan-200 focus:tw-ring-cyan-400 dark:tw-bg-cyan-200 dark:tw-text-cyan-600 dark:hover:tw-bg-cyan-300',
			pink: 'tw-bg-pink-100 tw-text-pink-500 hover:tw-bg-pink-200 focus:tw-ring-pink-400 dark:tw-bg-pink-200 dark:tw-text-pink-600 dark:hover:tw-bg-pink-300',
			lime: 'tw-bg-lime-100 tw-text-lime-500 hover:tw-bg-lime-200 focus:tw-ring-lime-400 dark:tw-bg-lime-200 dark:tw-text-lime-600 dark:hover:tw-bg-lime-300',
			dark: 'tw-bg-gray-100 tw-text-gray-500 hover:tw-bg-gray-200 focus:tw-ring-gray-400 dark:tw-bg-gray-200 dark:tw-text-gray-600 dark:hover:tw-bg-gray-300',
			indigo: 'tw-bg-indigo-100 tw-text-indigo-500 hover:tw-bg-indigo-200 focus:tw-ring-indigo-400 dark:tw-bg-indigo-200 dark:tw-text-indigo-600 dark:hover:tw-bg-indigo-300',
			purple: 'tw-bg-purple-100 tw-text-purple-500 hover:tw-bg-purple-200 focus:tw-ring-purple-400 dark:tw-bg-purple-200 dark:tw-text-purple-600 dark:hover:tw-bg-purple-300',
			teal: 'tw-bg-teal-100 tw-text-teal-500 hover:tw-bg-teal-200 focus:tw-ring-teal-400 dark:tw-bg-teal-200 dark:tw-text-teal-600 dark:hover:tw-bg-teal-300',
			light: 'tw-bg-gray-50 tw-text-gray-500 hover:tw-bg-gray-100 focus:tw-ring-gray-200 dark:tw-bg-gray-600 dark:tw-text-gray-200 dark:hover:tw-bg-gray-700 dark:hover:tw-text-white',
		},
	},
	color: {
		info: 'tw-text-blue-700 tw-bg-blue-100 tw-border-blue-500 dark:tw-bg-blue-200 dark:tw-text-blue-800',
		gray: 'tw-text-gray-700 tw-bg-gray-100 tw-border-gray-500 dark:tw-bg-gray-700 dark:tw-text-gray-300',
		failure: 'tw-text-red-700 tw-bg-red-100 tw-border-red-500 dark:tw-bg-red-200 dark:tw-text-red-800',
		success: 'tw-text-green-700 tw-bg-green-100 tw-border-green-500 dark:tw-bg-green-200 dark:tw-text-green-800',
		warning:
			'tw-text-yellow-700 tw-bg-yellow-100 tw-border-yellow-500 dark:tw-bg-yellow-200 dark:tw-text-yellow-800',
		red: 'tw-text-red-700 tw-bg-red-100 tw-border-red-500 dark:tw-bg-red-200 dark:tw-text-red-800',
		green: 'tw-text-green-700 tw-bg-green-100 tw-border-green-500 dark:tw-bg-green-200 dark:tw-text-green-800',
		yellow: 'tw-text-yellow-700 tw-bg-yellow-100 tw-border-yellow-500 dark:tw-bg-yellow-200 dark:tw-text-yellow-800',
		blue: 'tw-text-blue-700 tw-bg-blue-100 tw-border-blue-500 dark:tw-bg-blue-200 dark:tw-text-blue-800',
		cyan: 'tw-text-cyan-700 tw-bg-cyan-100 tw-border-cyan-500 dark:tw-bg-cyan-200 dark:tw-text-cyan-800',
		pink: 'tw-text-pink-700 tw-bg-pink-100 tw-border-pink-500 dark:tw-bg-pink-200 dark:tw-text-pink-800',
		lime: 'tw-text-lime-700 tw-bg-lime-100 tw-border-lime-500 dark:tw-bg-lime-200 dark:tw-text-lime-800',
		dark: 'tw-text-gray-200 tw-bg-gray-800 tw-border-gray-600 dark:tw-bg-gray-900 dark:tw-text-gray-300',
		indigo: 'tw-text-indigo-700 tw-bg-indigo-100 tw-border-indigo-500 dark:tw-bg-indigo-200 dark:tw-text-indigo-800',
		purple: 'tw-text-purple-700 tw-bg-purple-100 tw-border-purple-500 dark:tw-bg-purple-200 dark:tw-text-purple-800',
		teal: 'tw-text-teal-700 tw-bg-teal-100 tw-border-teal-500 dark:tw-bg-teal-200 dark:tw-text-teal-800',
		light: 'tw-text-gray-600 tw-bg-gray-50 tw-border-gray-400 dark:tw-bg-gray-500 dark:tw-text-gray-200',
	},
	icon: 'tw-me-3 tw-inline tw-h-5 tw-w-5 tw-flex-shrink-0',
	rounded: 'tw-rounded-lg',
	wrapper: 'tw-flex tw-items-center',
};
export default theme;
