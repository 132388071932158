import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const en = new URL('../../assets/data/locales/en.json', import.meta.url).href;
const tr = new URL('../../assets/data/locales/tr.json', import.meta.url).href;
const sa = new URL('../../assets/data/locales/sa.json', import.meta.url).href;

const languages: any = {
    en,
    tr,
    sa,
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: (lng: any) => languages[lng],
        },
        fallbackLng: 'en',
        debug: false,
        keySeparator: '.',
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: '.',
        },
    });

export default i18n;
