import type { FlowbiteSpinnerTheme } from 'flowbite-react';

const theme: FlowbiteSpinnerTheme = {
	base: 'tw-inline tw-animate-spin tw-text-gray-200',
	color: {
		failure: 'tw-fill-red-600',
		gray: 'tw-fill-gray-600',
		info: 'tw-fill-cyan-600',
		pink: 'tw-fill-pink-600',
		purple: 'tw-fill-purple-600',
		success: 'tw-fill-green-500',
		warning: 'tw-fill-yellow-400',
		primary: 'tw-fill-primary-500',
	},
	light: {
		off: {
			base: 'dark:tw-text-gray-600',
			color: {
				failure: '',
				gray: 'dark:tw-fill-gray-300',
				info: '',
				pink: '',
				purple: '',
				success: '',
				warning: '',
			},
		},
		on: {
			base: '',
			color: {
				failure: '',
				gray: '',
				info: '',
				pink: '',
				purple: '',
				success: '',
				warning: '',
			},
		},
	},
	size: {
		xs: 'tw-w-3 tw-h-3',
		sm: 'tw-w-4 tw-h-4',
		md: 'tw-w-6 tw-h-6',
		lg: 'tw-w-8 tw-h-8',
		xl: 'tw-w-10 tw-h-10',
	},
};
export default theme;
