import type { FlowbiteTabTheme } from 'flowbite-react';

const theme: FlowbiteTabTheme = {
	base: 'tw-flex tw-flex-col tw-gap-2',
	tablist: {
		base: 'tw-flex tw-text-center',
		styles: {
			default: 'tw-flex-wrap tw-border-b tw-border-gray-200 dark:tw-border-gray-700',
			underline: 'tw-flex-wrap -tw-mb-px tw-border-b tw-border-gray-200 dark:tw-border-gray-700',
			pills: 'tw-flex-wrap tw-font-medium tw-text-sm tw-text-gray-500 dark:tw-text-gray-400 tw-space-x-2',
			fullWidth:
				'tw-w-full tw-text-sm tw-font-medium tw-divide-x tw-divide-gray-200 tw-shadow tw-grid tw-grid-flow-col dark:tw-divide-gray-700 dark:tw-text-gray-400 tw-rounded-none',
		},
		tabitem: {
			base: 'tw-flex tw-items-center tw-justify-center tw-p-4 tw-rounded-t-lg tw-text-sm tw-font-medium first:tw-ms-0 disabled:tw-cursor-not-allowed disabled:tw-text-gray-400 disabled:dark:tw-text-gray-500 focus:tw-ring-4 focus:tw-ring-primary-400 focus:tw-outline-none focus:tw-z-10',
			styles: {
				default: {
					base: 'tw-rounded-t-lg',
					active: {
						on: 'tw-bg-gray-100 tw-text-primary-500 dark:tw-bg-gray-800 dark:tw-text-primary-500',
						off: 'tw-text-gray-500 hover:tw-bg-gray-50 hover:tw-text-gray-600 dark:tw-text-gray-400 dark:hover:tw-bg-gray-800 dark:hover:tw-text-gray-300',
					},
				},
				underline: {
					base: 'tw-rounded-t-lg',
					active: {
						on: 'tw-text-primary-500 tw-rounded-t-lg tw-border-b-2 tw-border-primary-500 active dark:tw-text-primary-500 dark:tw-border-primary-500',
						off: 'tw-border-b-2 tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-600 dark:tw-text-gray-400 dark:hover:tw-text-gray-300',
					},
				},
				pills: {
					base: '',
					active: {
						on: 'tw-rounded-lg tw-bg-primary-500 tw-text-white',
						off: 'tw-rounded-lg hover:tw-text-gray-900 hover:tw-bg-gray-100 dark:hover:tw-bg-gray-800 dark:hover:tw-text-white',
					},
				},
				fullWidth: {
					base: 'tw-ms-0 first:tw-ms-0 tw-w-full tw-rounded-none tw-flex',
					active: {
						on: 'tw-p-4 tw-text-gray-900 tw-bg-gray-100 active dark:tw-bg-gray-700 dark:tw-text-white tw-rounded-none',
						off: 'tw-bg-white hover:tw-text-gray-700 hover:tw-bg-gray-50 dark:hover:tw-text-white dark:tw-bg-gray-800 dark:hover:tw-bg-gray-700 tw-rounded-none',
					},
				},
			},
			icon: 'tw-me-2 tw-h-5 tw-w-5',
		},
	},
	tabpanel: 'tw-py-3',
};

export default theme;
