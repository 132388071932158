import type { FlowbiteTableTheme } from 'flowbite-react';

const theme: FlowbiteTableTheme = {
	root: {
		base: 'tw-w-full tw-text-start tw-text-md tw-text-gray-500 dark:tw-text-gray-400 tw-table-fixed',
		shadow: 'tw-absolute tw-bg-white dark:tw-bg-black tw-w-full tw-h-full tw-top-0 tw-left-0 tw-bottom-0 tw-right-0 tw-rounded-lg tw-drop-shadow-sm -tw-z-10',
		wrapper: 'tw-relative',
	},
	body: {
		base: 'tw-group/body',
		cell: {
			base: 'group-first/body:group-first/row:first:tw-rounded-ss-lg group-first/body:group-first/row:last:tw-rounded-se-lg group-last/body:group-last/row:first:tw-rounded-es-none group-last/body:group-last/row:last:tw-rounded-ee-none tw-px-6 tw-py-4',
		},
	},
	head: {
		base: 'tw-group/head tw-text-md tw-uppercase tw-text-gray-700 dark:tw-text-gray-400',
		cell: {
			base: 'group-first/head:first:tw-rounded-ss-lg group-first/head:last:tw-rounded-se-lg tw-bg-gray-100 dark:tw-bg-gray-800 tw-px-6 tw-py-3',
		},
	},
	row: {
		base: 'tw-group/row',
		hovered: 'hover:tw-bg-gray-50 dark:hover:tw-bg-gray-600',
		striped: 'odd:tw-bg-white even:tw-bg-gray-100 odd:dark:tw-bg-gray-800/[0.8] even:dark:tw-bg-gray-800',
	},
};

export default theme;
