import { Dispatch, SetStateAction } from 'react';

import useSessionStorage from '@hooks/storage/useSessionStorage';

import type { ILayoutData } from '@types';

export default function useLayout(initialData: ILayoutData) {
	const [storedData, setStoredData] = useSessionStorage(`layout`, initialData) as [
		ILayoutData,
		Dispatch<SetStateAction<ILayoutData>>,
	];

	return {
		layout: storedData,
		setLayout: setStoredData,
	};
}
