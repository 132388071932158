import React from 'react';
import classNames from 'classnames';

import { Skeleton } from 'antd';
import { Table } from 'flowbite-react';

import { generateRandomString, safeString } from '@utils';

export type IHeaderSkeleton = {
	className?: string;
	columnsCount: number;
};

/**
 * Renders a skeleton table header with a specified number of columns.
 *
 * @param {ITableHeaderSkeleton} props - The props object containing className and columnsCount.
 * @return {React.ReactElement} A React component representing the table header skeleton.
 */
export const HeaderSkeleton: React.FC<IHeaderSkeleton> = (props: IHeaderSkeleton): React.ReactElement => {
	const { className, columnsCount } = props;
	return (
		<Table.Head
			className={classNames('tw-h-[41.3px]', {
				[safeString(className)]: !!className,
			})}
		>
			{new Array(columnsCount).fill('').map(() => {
				return (
					<Table.HeadCell key={generateRandomString(6)} className="tw-text-center">
						<Skeleton className="[&_h3]:tw-mb-0" paragraph={false} active title />
					</Table.HeadCell>
				);
			})}
		</Table.Head>
	);
};
