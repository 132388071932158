import type { FlowbiteHelperTextTheme } from 'flowbite-react';

const theme: FlowbiteHelperTextTheme = {
	root: {
		base: 'tw-mt-2 tw-text-sm tw-mb-0',
		colors: {
			gray: 'tw-text-gray-500 dark:tw-text-gray-400',
			info: 'tw-text-cyan-700 dark:tw-text-cyan-800',
			success: 'tw-text-green-600 dark:tw-text-green-500',
			failure: 'tw-text-red',
			warning: 'tw-text-yellow-500 dark:tw-text-yellow-600',
		},
	},
};

export default theme;
