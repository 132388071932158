import type { FlowbiteTimelineTheme } from 'flowbite-react';

const theme: FlowbiteTimelineTheme = {
	root: {
		direction: {
			horizontal: 'tw-items-base sm:tw-flex',
			vertical: 'tw-relative tw-border-s tw-border-gray-200 dark:tw-border-gray-700',
		},
	},
	item: {
		root: {
			horizontal: 'tw-relative tw-mb-6 sm:tw-mb-0',
			vertical: 'tw-mb-4 tw-ms-0',
		},
		content: {
			root: {
				base: 'tw-mt-3 sm:tw-pe-8',
			},
			body: 'tw-mb-4 tw-text-base tw-font-normal tw-text-gray-500 dark:tw-text-gray-400',
			time: 'tw-mb-1 tw-text-sm tw-font-normal tw-leading-none tw-text-gray-400 dark:tw-text-gray-500',
			title: 'tw-text-lg tw-font-semibold tw-text-gray-900 dark:tw-text-white',
		},
		point: {
			horizontal: 'tw-flex tw-items-center',
			line: 'tw-hidden tw-h-0.5 tw-w-full tw-bg-gray-200 dark:tw-bg-gray-700 sm:tw-flex',
			marker: {
				base: {
					horizontal:
						'tw-absolute -tw-start-1.5 tw-h-3 tw-w-3 tw-rounded-full tw-border tw-border-white tw-bg-gray-200 dark:tw-border-gray-900 dark:tw-bg-gray-700',
					vertical:
						'tw-absolute -tw-start-1.5 tw-mt-1.5 tw-h-3 tw-w-3 tw-rounded-full tw-border tw-border-white tw-bg-gray-200 dark:tw-border-gray-900 dark:tw-bg-gray-700',
				},
				icon: {
					base: 'tw-h-3 tw-w-3 tw-text-primary-500 dark:tw-text-primary-300',
					wrapper:
						'tw-absolute -tw-start-3 tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-200 tw-ring-8 tw-ring-white dark:tw-bg-primary-900 dark:tw-ring-gray-900',
				},
			},
			vertical: '',
		},
	},
};
export default theme;
