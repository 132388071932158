import { useEffect } from 'react';

import useLayout from '@hooks/useLayout';

export const useRTL = () => {
	const { layout, setLayout } = useLayout({
		skin: 'light',
		isRTL: false,
		menuCollapsed: true,
	});

	const isRtl = layout.isRTL;

	const setValue = (value: boolean) => {
		setLayout((prev) => {
			return {
				...prev,
				isRTL: value,
			};
		});
	};

	useEffect(() => {
		const element = document.getElementsByTagName('html')[0];
		if (isRtl) {
			element.setAttribute('dir', 'rtl');
		} else {
			element.setAttribute('dir', 'ltr');
		}
	}, [isRtl]);

	return [isRtl, setValue] as [boolean, (val: boolean) => void];
};
