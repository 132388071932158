import { useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from 'flowbite-react';
import { MdClose } from 'react-icons/md';

import { GlobalDrawerUI } from '@contexts/GlobalDrawer';
import { useRTL } from '@hooks/useRTL';

/**
 * Renders a global off-canvas component.
 *
 * @return {React.ReactElement} The rendered component.
 */
export const GlobalDrawer: React.FC = (): React.ReactElement => {
	const { isOpen, setIsOpen, titleKey, innerComponent, footerComponent } = useContext(GlobalDrawerUI);
	const { t } = useTranslation();
	const [isRTL] = useRTL();

	if (!isOpen) {
		return <></>;
	}
	return (
		<div className="tw-z-[100] tw-h-full tw-w-full">
			<div
				className={classNames(
					'tw-fixed tw-top-0 tw-z-[101] tw-h-full tw-w-[30rem] -tw-translate-x-full tw-overflow-hidden tw-bg-white tw-text-gray-600 tw-transition-transform dark:tw-bg-gray-800 dark:tw-text-white md:tw-w-[32rem]',
					{
						'-tw-right-[30rem] md:-tw-right-[32rem]': !isRTL,
						'tw-left-[30rem] md:tw-left-[32rem]': isRTL,
					},
				)}
			>
				<div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-4">
					<h5 className="tw-mb-0">{t(titleKey)}</h5>
					<Button color="primary" size="xs" onClick={() => setIsOpen(false)}>
						<MdClose size={12} />
					</Button>
				</div>
				<div className="tw-h-[calc(100%-116px)] tw-overflow-y-auto tw-p-4">{innerComponent}</div>
				<div className="tw-fixed tw-bottom-0 tw-w-full">{footerComponent}</div>
			</div>
			<div
				className="tw-fixed tw-inset-0 tw-z-[100] tw-bg-gray-900 tw-bg-opacity-50 dark:tw-bg-opacity-80"
				onClick={() => setIsOpen(false)}
			></div>
		</div>
	);
};
