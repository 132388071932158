import type { FlowbitePaginationTheme } from 'flowbite-react';

const theme: FlowbitePaginationTheme = {
	base: '',
	layout: {
		table: {
			base: 'tw-text-sm tw-text-gray-600 dark:tw-text-gray-400',
			span: 'tw-font-semibold tw-text-gray-900 dark:tw-text-white',
		},
	},
	pages: {
		base: 'xs:tw-mt-0 tw-mt-0 tw-mb-0 tw-inline-flex tw-items-center -tw-space-x-px',
		showIcon: 'tw-inline-flex',
		previous: {
			base: 'tw-ms-0 tw-relative focus:tw-z-10 tw-rounded-s-lg tw-border tw-border-gray-300 tw-bg-white tw-py-2 tw-px-3 tw-leading-tight tw-text-gray-600 enabled:hover:tw-bg-gray-100 enabled:hover:tw-text-gray-700 dark:tw-border-gray-700 dark:tw-bg-gray-800 dark:tw-text-gray-400 enabled:dark:hover:tw-bg-gray-700 enabled:dark:hover:tw-text-white focus:tw-ring-2 focus:tw-ring-primary-500',
			icon: 'tw-h-5 tw-w-5',
		},
		next: {
			base: 'tw-rounded-e-lg tw-relative focus:tw-z-10 tw-border tw-border-gray-300 tw-bg-white tw-py-2 tw-px-3 tw-leading-tight tw-text-gray-600 enabled:hover:tw-bg-gray-100 enabled:hover:tw-text-gray-700 dark:tw-border-gray-700 dark:tw-bg-gray-800 dark:tw-text-gray-400 enabled:dark:hover:tw-bg-gray-700 enabled:dark:hover:tw-text-white focus:tw-ring-2 focus:tw-ring-primary-500',
			icon: 'tw-h-5 tw-w-5',
		},
		selector: {
			base: 'tw-w-12 tw-border tw-border-gray-300 tw-bg-white tw-py-2 tw-leading-tight tw-text-gray-600 enabled:hover:tw-bg-gray-100 enabled:hover:tw-text-gray-700 dark:tw-border-gray-700 dark:tw-bg-gray-800 dark:tw-text-gray-400 enabled:dark:hover:tw-bg-gray-700 enabled:dark:hover:tw-text-white',
			active: 'tw-bg-primary-50 tw-text-primary-500 hover:tw-bg-primary-100 hover:tw-text-primary-500 dark:tw-border-gray-700 dark:tw-bg-gray-700 dark:tw-text-white',
			disabled: 'tw-opacity-50 tw-cursor-normal',
		},
	},
};

export default theme;
